#shipment_component {
  option[type="radio"] {
    opacity: 0;
  }
  .noroadio[type="radio"] {
    opacity: 0;
  }
  #search {
    background-color: white;
  }

.removeafter::after{
  content: none !important;
}

  #search-1 {
    background: url(../../images/barcode-thin.svg) no-repeat 100% center;
    background-color: white;
    width: 50%;
    border-right: none;
  }
  .add-carrier-btn:disabled {
    background-color: rgb(0, 0, 0);
    color: $white;
    opacity: 0.7 !important;
    border: 0;
  }
  .addbtnc:disabled {
    background: $brand-primary-color;
    color: $white;
    opacity: 0.5 !important;
    border: 0;
    filter: brightness(0.9);
    &:hover {
      color: $white;
    }
  }
.InputContainer{
  background-color: #f4f4f4;
  height: fit-content;
  border-radius: 12px;

}



  .card0 {
    background-color: #f5f5f5;
    border-radius: 8px;
    z-index: 0;
  }
  input:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  .logo-1 {
    float: left;
    height: 27px;
  }
  .barcode_img {
    float: right;
    height: 27px;
    padding-right: 9px;
  }
  .manifest_label {
    text-align: right;
    font-size: 13px;
    margin-right: 90px;
    margin-top: -27px;
    font-family: 'open_sanssemibold';
  }

 

  .manifest_no_display {
    margin: 2px;
    float: right;
    width: 60px;
    margin-right: 16px !important;
    margin-top: -20px;
    font-size: 13px;
  }
  .barcode_img {
    display: block;
    width: 80px;
    height: 15px;
    margin: 0 auto;
    margin-top: -2px;
  }
  .scannerimgdc
  {
    margin-top: -10px;
    
  }
  .DCinputlabel
  {
    font-size: 13px;
    font-family: 'open_sanssemibold';
    padding-bottom: 10px;
    padding-left:5px;

  }
  .DCscanner{
    height: 35px;
    border: 1px solid #f0f0f0;
    border-left: none;
    background-color: white;
}
.width100{
  width:100%;
}
  
  .inputDCCOnf{
    height: 35px;
    border: 1px solid #f0f0f0;
    border-right: none;
    font-size: 13px;
    background: url(../../images/search-icon-sm.svg) no-repeat 4% center;
    padding-left: 12% !important;
    background-color: white;

  }
  .rmsc{
  float: right;
  width: 60% !important; 
  background-color: #f4f4f4;

  }
#ManifestList
  {
  input[type=checkbox]{
    display: none;
  }
}
  .rmsc .search input
  {
    width:95%;
    margin-left: 2%;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    background: url(../../images/search-icon-sm.svg) no-repeat 4% center;
    padding-left: 12% !important;
  }
  .rmsc .search
  {
    border:none;
  }
  .dropdown-content{
    width:166%;
    margin-left: -66%;
  }
  .dropdown-container{
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .dcdespatch-search
  {
  border:1px solid #ced4da !important;
  border-radius: 5px;
}
  #dcviewmanifest
  {

  .dropdown-menu
  {
    width:100% !important;
    min-width: 10px;
  }
  .dropdown-search_dc {
    background-color: white !important;
    color: black !important;
    width: 200px ;
    padding-right: 30px;
    text-align: left !important;
    margin-top: 0px !important;
    height: 40px !important;
    border-radius: 0px ;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-color: #ced4da !important;
    text-decoration-color: white !important;
    font-size: 12px;
    font-weight: 100;
    background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
  }
  }

  .manifest_header{
    font-size: 16px;
    font-family: 'open_sanssemibold';
    padding-top: 5px;
    padding-right: 90px;
  }


  .bottom_count {
    background-color: white;
    padding: 15px 0px;
    border-radius: 4px;
    padding-left: 10px;
    margin-left: 5px;
    width: 180px;
    box-shadow: 0px 0px 5px #888888;

    text-align: center;
  }
  .pag_ship {
    margin-top: -30px !important;
  }
  .card00 {
    z-index: 0;
  }
  .paginat {
    margin-top: 8%;
  }
  .stepper {
    width: 14%;
  }

  .card1 {
    margin-left: 50px;
    z-index: 0;
    margin-right: 5px;
    border-right: 1px solid #e0e0e0;

    height: 500px;
  }

  .card2 {
    display: none;
  }

  .card2.show {
    display: block;
  }

  .social {
    border-radius: 50%;
    background-color: #ffcdd2;
    color: #e53935;
    height: 47px;
    width: 47px;
    padding-top: 16px;
    cursor: pointer;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: #e53935;
  }

  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
    width: 77%;
  }

  .form-control-placeholder {
    position: absolute;
    top: 0px;
    padding: 12px 2px 0 2px;
    transition: all 300ms;
    opacity: 0.5;
  }

  .form-control:focus + .form-control-placeholder,
  .form-control:valid + .form-control-placeholder {
    font-size: 95%;
    top: 10px;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    background-color: #fff;
  }
  .section-three {
    background-color: #fff;
    padding-top: 10px;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    height: auto;
    // margin-bottom: 20px;
  }
  .card-columns {
    margin-top: 15px;
  }
  .get-bonus {
    margin-left: 154px;
  }
  .ref_in {
    background: url(../../images/barcode-thin.svg) no-repeat 98% center;
  }
  .cubl_scan_btn {
    border-radius: 0px 10px 10px 0px;
    border-right: 0.25px solid rgb(189, 189, 189) !important;
  }

  .pic {
    width: 230px;
    height: 110px;
  }

  #progressbar {
    position: absolute;
    left: -32px;
    overflow: hidden;
    color: #e53935;
  }

  #progressbar li {
    list-style-type: none;
    display: block;
    font-size: 8px;
    font-weight: 100;
  }
  #progressbar li:nth-child(1) {
    margin-bottom: 23px;
  }
  #progressbar li:nth-child(2) {
    margin-bottom: 23px;
  }

  #progressbar li:nth-child(3) {
    margin-bottom: 88px;
  }

  #progressbar .step0:before {
    content: "";
    color: #fff;
  }

  #progressbar li:before {
    content: "11111111111";
    width: 20px;
    height: 20px;
    line-height: 20px;
    display: block;
    font-size: 12px;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 50%;
    margin: auto;
    border: 1px solid #bdbdbd;
  }
  .width100{
    width:100% !important;
  }
  .shipment_header {
    margin-top: 1%;
    margin-left: 2px;
  }

  #progressbar li:after {
    content: "";
    width: 2px;
    /* height: 43px; */
    background: #bdbdbd;
    position: absolute;
    left: 49px;
    display: block;
    clear: both;
    /* top: 48px; */
    z-index: 2;
  }
  #progressbar li:nth-child(1):before {
    margin-top: 3px;
  }

  #progressbar li:nth-child(1):after {
    position: absolute;
    height: 23px;
  }
  #progressbar li:nth-child(2):after {
    position: absolute;
    height: 23px;
  }
  #progressbar li:nth-child(3):after {
    height: 0%;
  }
  #progressbar li:nth-child(2):before {
    content: "2";
    color: black;
  }
  #progressbar li:nth-child(3):before {
    content: "3";
    color: black;
  }

  #progressbar li.active:before {
    background: #689f41;
    font-family: FontAwesome;
    color: #fff;
    content: "\f00c";
  }

  .tick {
    width: 100px;
    height: 100px;
  }

  .prev {
    display: block;
    position: absolute;
    left: 40px;
    top: 20px;
    cursor: pointer;
  }
  .label {
    display: inline-block;
  }
  .prev:hover {
    color: #d50000 !important;
  }
  .view {
    color: white;
    border: none;
    outline: none;
  }

  th {
    cursor: default;
  }

  table {
    padding-bottom: 0px;
    margin-bottom: 0px;
    text-align: center;
    overflow-x: scroll !important;
  }
  .mdb-dataTable_scrollBody {
    table {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 912px) {
    .card00 {
      padding-top: 30px;
    }

    .card1 {
      border: none;
      margin-left: 50px;
    }

    .card2 {
      border-bottom: 1px solid #f5f5f5;
      margin-bottom: 25px;
    }

    .social {
      height: 30px;
      width: 30px;
      font-size: 15px;
      padding-top: 8px;
      margin-top: 7px;
    }

    .get-bonus {
      margin-top: 40px !important;
      margin-left: 75px;
    }

    #progressbar {
      left: -25px;
    }
  }
  .mrt14 {
    margin-top: 14px;
  }
  .mb21 {
    margin-bottom: 10px;
    margin-left: -30px;
    padding: 0px;
  }

  .pl24 {
    padding-left: 24px;
  }
  .pt10 {
    padding-top: 10px;
  }
  .ctfinput {
    border-radius: 10px 0px 0px 10px !important;
    height: 42px !important;
    width: 10% !important;

    border: 0.25px solid rgb(189, 189, 189);
    border-right: none;
  }
  .cblInput {
    border-radius: 10px 0 0 10px;
    height: 42px !important;
    width: 4% !important;
    margin-left: 10px;
    padding-left: 20px !important;
    border: 0.25px solid rgb(189, 189, 189);
    border-right: none !important;
  }
  .ctfinput_1 {
    border-radius: 10px 0px 0px 10px !important;
    height: 42px !important;
    width: 15px !important;

    border: 0.25px solid rgb(189, 189, 189);
    border-right: none;
  }

  .dest {
    border-radius: 5px;
    border-right: 0.25px solid rgb(189, 189, 189) !important;
  }
  .view-manifest-btn:disabled,
  .print-manifest-btn:disabled,
  .complete-shipment-btn:disabled {
    opacity: 70% !important;
  }
  .ctf {
    width: 50%;
  }
  .clr {
    width: 30px;
    border: 0.25px solid rgb(189, 189, 189);
    border-right: none;
    border-left: none;
    background-color: white;
    background-image: url("../../images/clear-icon.svg");
    background-size: contain;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    //border: 0.25px solid rgb(189, 189, 189);
  }
  .leftm {
    margin-left: 10px;
  }
  .scan_button {
    border: 0.25px solid rgb(189, 189, 189);
    border-right: none;
    border-left: none;
    background-image: url("../../images/barcodescanner.png");
    background-size: contain;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    width: 30px;
    padding: 0px;
    margin: 0px;
    cursor: default;
    pointer-events: none;
  }
  .empt_button {
    border: 0.25px solid rgb(189, 189, 189);
    border-right: none;
    border-left: none;
    background-color: white;
    width: 10px;
    padding: 0px;
    padding-right: 30px;
    padding-bottom: 5px;
    margin: 0px;
  }
  .empt_button:focus {
    outline: none;
    box-shadow: none;
  }
  .search_button {
    width: 35px;
    border-radius: 0 10px 10px 0 !important;
    background-color: gray;
    background-image: url("../../images/Search_Icon_Gray.png");
    background-size: contain;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    border: 0.25px solid rgb(189, 189, 189);
    border-left: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search_button-1 {
    width: 110px;
    border-radius: 0 10px 10px 0 !important;
    background-color: rgb(89, 89, 89) !important;
    color:white;
    background-size: contain;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    border: 0.25px solid rgb(189, 189, 189);
    border-left: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../../images/down-arrow-icon.svg) no-repeat 90% center !important;
  }
  .search_button-1[aria-expanded="true"] {
    background: url(../../images/up-arrow-icon.png) no-repeat 90% center !important;
  }
  .input-group-append{
    background: gray;
    border-radius:10px;
  }
  .add-buttons{
    border-top:1px solid rgba(151,151,151,0.3);
    .add-carrier-btn{
      width:100px;
    }
  }
  .addbtnc {
    width: 40%;
    height: 35px !important;
    margin-top: 4px;
    border-radius: 8px;
    background: #ff3d1b;
    color: #fff;
    display: inline;
  }
  .cblubllist{
    height: 200px;
  }
  .addbtnc_1 {
    width: 20%;
    height: 35px !important;
    margin-top: 4px;
    margin-left: 5% !important;
    border-radius: 8px;
    background: #ff3d1b;
    color: #fff;
  }

  .modi_val {
    margin-top: -20px;
    margin-left: 23%;
  }
  .cre_val {
    //margin-top: -25px;
    size: 12px;
    //margin-left: 18%;
  }
  .addbtnc_1:disabled {
    background: $brand-primary-color;
    border-radius: 5%;
    color: $white;
    opacity: 0.5 !important;
    border: 0;
    filter: brightness(0.9);
  }

  .doc-icon {
    margin-left: 10px;
    margin-right: 11px;
    height: 15px;
    width: 15px;
  }
  table {
    width: 100%;
  }
  .doc-circle {
    border: 1px solid;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    /* margin-left: 10px; */
    margin-left: 10px;
    margin-right: 10px;
    /* background: #787878; */
    color: #fff;
  }
  .dspc {
    padding-top: 10px;
  }
  .add {
    padding-left: 0;
  }
  .mftText {
    margin-top: 15px;
    margin-right: 5px;
    font-size: 100%;
    display: inline;

    float: right !important;
    .manifest {
      font-weight: bold !important;
    }
  }

  .progressIcon {
    padding-top: 5px;
  }
  .card-sect2 {
    border-radius: 12px;
    padding-top: 13px;
    padding-bottom: 10px;
  }
  .card-sect2_cr {
    border-radius: 12px;
    padding-top: 13px;
    padding-bottom: 10px;
    height: 15%;
  }
  .label {
    padding-left: 2%;
    margin-top: 1%;
    display: inline-block;
    width: 20%;
    align-content: flex-start;
    text-overflow: ellipsis !important;
  }
  .zero {
    padding: 0px;
    margin: 0px;
  }
  .icon_div {
    margin-left: -11px !important;

    .fs12 {
      font-size: 85%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .fs10 {
      font-size: 75%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .cbl-text {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    font-weight: 700;
  }
  .cbl-container {
    border: 1px solid;
    padding: 10px;
    border-radius: 10px;
    height: 358px;
    border-color: #fff;
    box-shadow: 2px 2px 10px #888888;
    margin-bottom: 1em !important;
  }
  .card-columns {
    column-count: 4 !important;
  }
  .section-four {
    background-color: #fff;
    box-shadow: 0px 0px 10px #888888;
    padding: 10px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0 0 10px 10px;
  }
  .add-carrier-btn {
    border-color: #000000;
    border-radius: 5px;
    height: 35px !important;
    width: 140px;
    background-color: black;
    color: #fff;
  }
  .dcsearch{
    border-color: #000000;
    border-radius: 5px;
    padding: 5px;
    height: 30px;
    margin-top: 18px;
    width: 80px;
    background-color: black;
    color: #fff;

  }
  .dcsearch:disabled{
    background-color: gray;
    border-color: gray;
  }
  .complete-shipment-btn {
    border-color: #000000;
    border-radius: 5px;
    height: 35px !important;
    width: 160px;
    background-color: black;
    color: #fff;
    font-size: 13px;
  }
  .complete-shipment-btnDC {
    border-color: #000000;
    border-radius: 5px;
    height: 35px !important;
    width: 160px;
    background-color: black;
    color: #fff;
    font-size: 13px;
    
  }
  .complete-shipment-btnDC:disabled{
    background-color: gray;
    border-color: gray;

  }
  .cancel-btn {
    margin-right: 30px;
    border: none !important;
    background: none;
    color: #2f2f2f;
    font-weight: 500;
  }
  .blue1 {
    color: #2c80fd !important;
    cursor: pointer;
  }
  .Select-form {
    height: 35px !important;
    width: 50% !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-bottom: 0px;
  }
  .text-form {
    height: 35px !important;
    width: 50% !important;
    border-radius: 5px !important;
    font-size: 12px !important;
  }
  .print-manifest-btn,
  .view-manifest-btn {
    margin-right: 10px;
    border: 1px solid !important;
    /* margin-right: 10px; */
    border-color: #3a3a3a;
    height: 35px !important;
    color: #3a3a3a !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
    padding: 9px !important;
    background: none !important;
    border-radius: 6px;
  }
  .exit-manifest-btn {
    margin-right: 10px;
    border: none !important;
    height: 35px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
    padding: 9px !important;
    background: none !important;
    width: 100px;
    padding: 0px;
    margin: 0px;
    margin-right: 2px;
  }
  .border_radius{
    border-radius: 12px !important;
  }
  .card-section {
    border: 1px solid;
    border-radius: 7px;
    border-color: whitesmoke;
  }
  .manifest-header {
    background: #cdeff9;
    padding: 10px;
    height: 50px;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .manifest-container {
    background: #f5f5f5;
    padding: 10px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .align-middle
  {
    padding: 10px !important ;
  }
  .flex {
    display: flex;
  }
  .pl10em {
    padding-left: 10em;
  }

  .border-top-modify {
    border-top: 1px solid #dbdbdb;
    margin-top: 10px;
    padding-top: 10px;
  }

  // Data Table
  datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
  }
  .scan_button {
    padding: 0px;
    margin: 0px;
  }

  .datatable-filter-demo .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
  }
  .Scanner_Shipment {
    object-fit: contain;
    border: none;
    margin: none;
    background-image: "";
  }

  .datatable-filter-demo .table-header {
    display: flex;
    justify-content: space-between;
  }

  .datatable-filter-demo .p-datepicker {
    min-width: 25rem;
  }

  .datatable-filter-demo .p-datepicker td {
    font-weight: 400;
  }

  .datatable-filter-demo
    .p-datatable.p-datatable-customers
    .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
  }

  .datatable-filter-demo
    .p-datatable.p-datatable-customers
    .p-datatable-thead
    > tr
    > th {
    text-align: left;
  }

  .datatable-filter-demo
    .p-datatable.p-datatable-customers
    .p-datatable-tbody
    > tr
    > td {
    cursor: auto;
  }

  .datatable-filter-demo
    .p-datatable.p-datatable-customers
    .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }

  .datatable-filter-demo
    .p-datatable-customers
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .datatable-filter-demo
      .p-datatable.p-datatable-customers
      .p-datatable-thead
      > tr
      > th,
    .datatable-filter-demo
      .p-datatable.p-datatable-customers
      .p-datatable-tfoot
      > tr
      > td {
      display: none !important;
    }

    .datatable-filter-demo
      .p-datatable.p-datatable-customers
      .p-datatable-tbody
      > tr {
      border-bottom: 1px solid var(--layer-2);
    }

    .datatable-filter-demo
      .p-datatable.p-datatable-customers
      .p-datatable-tbody
      > tr
      > td {
      text-align: left;
      display: block;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;
    }

    .datatable-filter-demo
      .p-datatable.p-datatable-customers
      .p-datatable-tbody
      > tr
      > td
      .p-column-title {
      padding: 0.4rem;
      min-width: 30%;
      display: inline-block;
      margin: -0.4rem 1rem -0.4rem -0.4rem;
      font-weight: bold;
    }

    .datatable-filter-demo
      .p-datatable.p-datatable-customers
      .p-datatable-tbody
      > tr
      > td
      .p-progressbar {
      margin-top: 0.5rem;
    }
  }

  svg:not(:root).svg-inline--fa {
    color: #7b7b7b;
  }
  table.mdb-dataTable thead th,
  table thead.mdb-dataTable-head th {
    font-size: 12px;
  }
  th.sorting {
    padding-right: 20px !important;
  }
  li.nav-item {
    list-style: none;
    // padding: 0px !important;
    // margin: 0;
    // width: auto;
  }
  .barcode-labels {
    position: relative;
    top: 6px;
  }

  .dropdown-menu {
    left: -104px;
    transform: translate3d(0px, 40px, 0px);
    padding: 10px;
  }
  .fa-eye {
    color: #3a89fd !important;
  }
  .scanner-img {
    position: absolute;
    height: 30px;
    width: 30px;
    /* left: -53px; */
    right: 3em;
    top: 6px;
  }
  .delete-icon {
    font-size: 12px;
    font-weight: normal;
    color: #777778 !important;
    position: absolute;
    right: 1.5em;
    top: 12px;
    font-family: auto;
    border: none;
    background: none;
    margin-right: -10px;
  }
  #shipment_card {
    .card-header {
      padding-left: 10px !important;
      padding-top: 10px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
    }
  }
  .p10 {
    padding: 10px !important;
  }
  .card-text {
    padding-bottom: 5px;
  }
  .pl10 {
    padding-left: 10px;
  }
  .pl16 {
    padding-left: 16px;
  }
  .ml-1 {
    margin-left: 0px !important;
  }
  .mdb-datatable-filter.form-control-sm {
    height: 30px !important;
    width: 220px;
    margin-bottom: 15px !important;
  }

  .border-none {
    border: none !important;
  }
  .ctbtn {
    border-radius: 10px !important;
    height: 40px;
    text-decoration: none;
  }
  .box-shadow-none {
    box-shadow: none !important;
  }
  .bg-none {
    background: none !important;
  }

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;

    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  .modify-form-input:disabled{
    background-color: #e9ecef !important;
  }

  #shipment_card {
    .modal.left .modal-content,
    .modal.right .modal-content {
      height: 100%;
      overflow-y: auto;
    }
  }

  .modal.left .modal-body,
  .modal.right .modal-body {
    padding: 15px 15px 80px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .modal.right.fade.in .modal-dialog {
    right: 0;
  }

  /* ----- MODAL STYLE ----- */
  #shipment_card {
    .modal-content {
      border-radius: 0;
      border: none;
    }

    .modal-header {
      border-bottom-color: #eeeeee;
      background-color: #fafafa;
    }
  }

  .width2 {
    width: 205px;
  }
  .cttable {
    overflow: hidden;
    border-radius: 5px 5px 0px 0px;
    // -webkit-box-shadow: 0 1px 1px #ccc !important;
    // -moz-box-shadow: 0 1px 1px #ccc;
    // box-shadow: 0 1px 1px #ccc;
    thead {
      border: solid #ececec 1px;
    }
    tbody {
      border: solid #ececec 1px;
    }

    td {
      border: none !important;
    }
    th {
      border: none !important;
    }
  }
  .No_button:hover {
    background-color: black !important;
    text-decoration: none !important;
    border-radius: 10px;
  }
  .mt-4p {
    margin-top: 4px;
  }

  .ct-select-option {
    border-radius: 10px !important;
    height: 40px !important;
    width: 35% !important;
    font-size: 12px !important;
    -webkit-appearance: menulist-button;
    -moz-appearance: none;
  }
  .ct-text-input {
    border-radius: 10px !important;
    height: 40px !important;
    width: 50% !important;
    font-size: 12px !important;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .scanner-img1 {
    height: 30px;
    width: 30px;
  }
  .dropdown-search_1 {
    background-color: white !important;
    color: black !important;
    width: 200px ;
    padding-right: 30px;
    text-align: left !important;
    margin-top: 0px !important;
    height: 40px !important;
    border-radius: 0px !important;

    border-color: #ced4da !important;
    text-decoration-color: white !important;
    font-size: 12px;
    font-weight: 100;
    background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
  }
  .msswd{width:200px !important;}
  .no-item {
    text-align: center;
    margin-top: 5em;
  }
  .edit-btn {
    width: 80px;
    height: 35px !important;
    margin-top: 4px;
    border-radius: 8px;
    color: #fff;
    border-color: #1b76fd;
    background: #fff;
    font-size: 15px;
    margin-left: 20px !important;
  }
  .pl-4p {
    padding-left: 4px;
  }
  .modify-form-input {
    height: 35px !important;
    width: 386px !important;
    font-size: 12px !important;
  }

  .separator {
    border: 1px solid;
    width: 100%;
    height: 1px;
    margin-top: 3em;
    border-color: #e0e0e0;
  }
  .rightfloat {
    text-align: right;
    float: right !important;
  }
  .modify-btn {
    width: 80px;
    border-color: #e0e0e0 !important;
    margin-right: 10px;
    border: 1px solid !important;
    height: 35px !important;
    color: #3a3a3a !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
    padding: 9px !important;
    background: none !important;
    border-radius: 6px;
  }
  .form-control-sm {
    height: 32px;
  }
  .flr {
    float: right;
  }
  th {
    font-weight: bolder !important;
  }

  @media only screen and (max-width: 700px) {
    .card-header {
      height: 30% !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .msswd{width:150px !important;}
    .dropdown-search_1{width:150px}
    #sideprogress{
      display: none;
    }
    #Despatch_Component{
    
      .table-responsive::-webkit-scrollbar {
       // width: 4px;
        height: 03px;
      }
      .table-responsive::-webkit-scrollbar-track {
        background: white;
      }
      .table-responsive::-webkit-scrollbar-thumb {
        background: #888;
      }
      .table-responsive::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .mdb-dataTable_scrollBody table {
      padding-bottom: 0px;
      margin-bottom: 0px;
      }
    }

    #Despatch_Component{
      .confirmation-header{
       background-color: #888;
      }
      .bo-modalbtnftr{
        justify-content: center;
      }
      .confirm-no{
        width:45%;
        border:1px solid black;
      }
      .confirm-yes
      {
        width: 45%;
      }
    
    }
    .addbtnc_1{
      width:30%;
      margin-left: 3% !important;
    }
    .ssml10 {
      margin-left: 10px;
    }
    .pag_ship {
      margin-top: 10px !important;
    }
    .w100 {
      width: 100% !important;
    }
    .min {
      width: 90%;
    }
    input.min {
      width: 31%;
      margin-right: 5px !important;
      padding: 0px;
    }
    .form-group {
      width: 98%;
      padding-bottom: 0px;
      margin-bottom: 05px;
      margin-left: 5px !important;
      margin-right: 5px !important;
      float: left;
    }

    .add {
      margin-top: 10px;
      justify-content: center;
      margin-left: 24px;
    }
    .ctf {
      padding-right: 0px !important;
      width: 70%;
    }
    .label {
      white-space: nowrap;
      margin-left: 5px;
    }

    .card-sect2_cr {
      height: 170px;
    }

    .card-columns {
      justify-content: center !important;
    }
    .cbl-container {
      min-height: 200px;
      width: 95% !important;
      height: max-content;
    }
    .manifest_label {
      margin-top: 0px;
    }

    #myModal12 {
      width: 50%;
    }
    #myModal2 {
      .modal-body {
        justify-content: right;
        padding-left: 0px;
        margin-left: 5px;
        width: 98%;
        overflow-x: scroll;
        table {
          width: 97%;
        }
      }
    }

    .close {
      margin-right: 3px;
      padding-top: 22px;
    }

    .cttable {
      margin-left: -5px;
      width: 97%;

      overflow-x: scroll;
    }
    .modal-dialog-1 {
      width: 100%;
      height: 100%;
    }
    .cre_val {
      margin-top: 0px;
      //margin-left: 20px;
    }
    .modal-dialog {
      width: 90%;
      margin-left: 2.5%;
    }
    .Select-form,
    .text-form {
      width: 80% !important;
    }
    .print-manifest-btn,
    .cancel-btn {
      margin-left: 0px;
      // margin-right: 2px;
      padding: 0px;
    }
    .print-manifest-btn {
      width: 25%;
      padding: 0px;
      margin: 0px;
      margin-right: 2px;
    }
    .view-manifest-btn {
      width: 25%;
      padding: 0px;
      margin: 0px;
      margin-right: 2px;
    }

    .complete-shipment-btn {
      width: 130px;
      padding: 0px;
      margin: 0px;
    }
    .complete-shipment-btnDC {
      width: 130px;
      padding: 0px;
      margin: 0px;
    }
    .complete-shipment-btnDC:disabled {
      background-color: gray;
    }
    .ct-select-option {
      border-radius: 10px !important;

      width: 65% !important;
      font-size: 12px !important;
      padding-left: 10px;
      margin-left: 5px;
      overflow-y: auto;
    }
    .Select_modify {
      height: 40px;
    }
    .ct-select-option select {
      width: 400px !important;
    }

    .addbtnc_1 {
      padding-left: 10px;
      margin-left: 5px;
    }
    .edit-btn {
      padding-left: 10px;
      margin-left: 5px;
    }
    .label {
      margin-bottom: 5px;
      margin-right: 5px;
    }

    .modify-form-input {
      width: 70% !important;
    }
    .modi_val {
      margin-top: 0px;
      margin-left: 30px;
      size: 12px;
    }
    .ctfinput_1 {
      font-size: 12px;
    }
  }

  .ff-openbold {
    font-family: "open_sansbold";
  }
  .gscale {
    filter: grayscale(1);
  }

  .ff-openregular {
    font-family: "open_sansregular";
  }

  .no-items-found-shipment {
    position: absolute;
    left: 45%;
    top: 50%;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .manifest-header-carrier {
    height: 80px;
    padding-top: 30px;
    padding-left: 15px;
    font-family: "open_sansregular";
    display: flex;
  }

  .manifest-number-header {
    font-weight: 700 !important;
    padding-left: 7px;
  }
}
