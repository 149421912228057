#dcitemputaway_component {
  .hr_line {
    margin-right: -10px;
    margin-left: -10px;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
  }
  .form-inline {
    padding-bottom: 10px;
  }
  .input-search-dcitem {
    background: url(../../images/barcode-thin.svg) no-repeat 98% center;
    padding-right: 30px;
    padding-left: 35px;
    border-radius: 5px;
  }
  .dc-location {
    border-radius: 5px !important;
  }
  .dc_alert_box_button{
    width:45%;
    border-radius: 10px;
    padding-right: 0px;
    padding-left:0px;

  }
  .faSearch {
    position: absolute;
    top: 15px;
    left: 17px;
    color: gray;
  }
  .faplus {
    background: #ff4000;

    color: white;
  }
  .faMapMarker {
    position: absolute;
    top: 13px;
    left: 11px;
    color: gray;
  }
  .bg-color {
    background: #f8f8f8;
  }
  .rtn-search-results {
    min-height: 70vh;
    padding: 10px 0px;
    border-radius: 0px;
  }
  .fapMark-margin {
    margin: 5px 5px 0px 0px;
  }

  .dcitem-scanner-img {
    position: relative;
    height: 25px;
    width: 25px;
    right: 8.7em;
    top: 7.5px;
    z-index: 888;
  }

  .putaway-input-section {
    box-shadow: none;
    border-radius: 0px;
    padding-bottom: 10px !important;
    border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  }
  .dcitem-scanner {
    position: relative;
    height: 25px;
    width: 25px;
    right: 5.7em;
    top: 7.5px;
    z-index: 888;
  }
  .barcode-validation-error {
    font-family: "open_sanssemibold";
    font-size: 10px;
    color: #ff4000;
    margin-left: 155px;
  }
  @media only screen and (max-width: 600px) {
    .barcode-validation-error {
      margin-left: 0px;
    }
  }
  .dc_location {
    font-size: 13px;
  }
  .search-panel label {
    padding: 0px;
    font-size: 12px;
  }
  .section-four {
    background-color: #fff;
    box-shadow: 0px 0px 10px #888888;
    padding: 10px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0 0 10px 10px;
  }
  .ctbtn {
    border-radius: 10px !important;
    height: 40px;
    text-decoration: none;
    background-color: #ff4000;
    color: white;
  }
  .complete-putaway-btn {
    border-color: #000000;
    border-radius: 5px;
    height: 35px !important;
    width: 140px;
    background-color: black;
    color: #fff;
  }
  .cancel-btn {
    margin-right: 3em;
    border: none !important;
    background: none;
    color: #2f2f2f;
    font-weight: 500;
  }
  .btn-primary {
    background-color: #ff4000;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

#dcItemPutawayModify {
  .header {
    background-color: #f8f8f8;
    padding: 15px;
    p {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      margin: 0;
    }
  }

  .input-search-dcitem {
    background: url(../../images/barcode-thin.svg) no-repeat 98% center;
    padding-right: 30px;
    padding-left: 35px;
    border-radius: 5px;
    font-size: 14px;
    height: 38px;
  }
}
