/* You can add variables styles to this file, and also import other style files */
$bg-grey:            #f5f5f5;


$black: #000000;
$white: #ffffff;

$light-blue: #F2FCFE;

$border-color: #c8c8c8;
$border-979797: #979797;
$border-c6c6c6: #c6c6c6;
$border-cbcbcb: #cbcbcb;
$border-bebebe: #bebebe;
$border-d8d8d8: #d8d8d8;


$brand-primary-color: #ff4000;

$label-text-color: #3d3d3d;

$text-color-8D8D8D: #8D8D8D;

$text-color: #3d3d3d;


$bg-fafafa: #fafafa;
$bg-color: #e6e6e6;
$text-color: #292929;
$color797979: #797979;
$color0255e1: #0255e1;
$colora6a6a6: #a6a6a6;
$color0f7bc8: #0f7bc8;
$bg-grey: #eeeeee;
$header-bg: #323232;
$navbar-bg: #e4cea9;
$dropdown-bg: #212121;

$anchor-text: #0060ea;

$secondary-text-color: #ff7e00;
$btn-secondary: #ff8a17;
$orange-btn: #feb73c;

$bg-f8f8f8: #f8f8f8;

.w-0 { width:0%!important; }
.w-20 { width:20%!important; }
.w-40 { width:40%!important; }
.w-60 { width:60%!important; }
.w-80 { width:80%!important; }

.color292929 {
  color: $text-color;
}
.color797979 {
  color: $color797979;
}
.colorff7e00 {
  color: $secondary-text-color;
}
.color0255e1 {
  color: $color0255e1;
}
.colora6a6a6 {
  color: $colora6a6a6;
}
.color0f7bc8 {
  color: $color0f7bc8;
}

.black-gradientBtn {
  color: $white;
  border-radius: 3px;
  background: $orange-btn;
  background-image: -webkit-linear-gradient(top, #323232, #000000);
  background-image: -moz-linear-gradient(top, #323232, #000000);
  background-image: -ms-linear-gradient(top, #323232, #000000);
  background-image: -o-linear-gradient(top, #323232, #000000);
  background-image: linear-gradient(to bottom, #323232, #000000);
}
