
/*
 * Sidebar
 */

 #mynavbar
 {
   margin-top: 1%;
   margin-left: 15px;
   
 }


 .active-submenu{
  background: #f5f8ff;
  width: 80%;
  border-radius: 10px;
  margin-left:8%;
 
  .nav-link{
    font-weight: bold !important;

  }

}
.sub-ul
{
  margin-top: 5%;
  border-left: 1px solid  rgb(189, 189, 189); 
}

.inactive-submenu
{
  width: 80%;
  border-radius: 10px;

  margin-left: 8%;
}

 .sidebar {
    position: fixed;
    top:0%;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    overflow-y:hidden;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    border-radius: 0 22px 22px 0;
    
  }
  
  @media (max-width: 767.98px) {
    .sidebar {
      top: 4rem;
    }
  }
  
  .sidebar-sticky {
    position: fixed;
    float: top;
    top: 0%;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y:scroll;
    -ms-overflow-style: none;
     /* Scrollable contents if viewport is shorter than content. */

     
  }
  .sidebar-sticky::-webkit-scrollbar {
    display: none; 
}

  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
    }
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
  }
  
  .sidebar .nav-link.active {
    color: #007bff;
  }
  

  .sidebar .nav-link:hover 
  {
    color:white ;
    background-color: black ;

  }

  .sidebar .nav-link.active
  {
    color:white !important;
    background-color: black !important;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }

  .ctnav-items{
    padding-top: 10px;
    margin-top: 30px;
    width: 100%;
  }
