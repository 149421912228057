/* Smartphones (portrait and landscape) ----------- */
@media (max-width: 575.98px) {
  .sidebar {
    position: absolute !important;
    height: auto;
    z-index: 9;
  }
  .searchorderSearch{
    margin-left: 5px;
  }
  .searchorderSearchdiv{
    margin-top: 10px;
  }
  .rmsc{
    max-width: 310px;
  }
  .sidebar .nav-link:hover
  {
    color:black ;
    background-color: white;
  }

  .returns-mainmenu {
    padding: 0;
  }

  .navbar-dark .navbar-toggler {
    margin-top: -10px;
    border-color: rgba(255, 255, 255, 0) !important;
    padding: 25px 0;
  }

  .dropdown-profile {
    background-color: #ff4000 !important;
    color: white !important;
    text-decoration-color: #ff4000 !important;
    background: url(../../../images/down-arrow-icon.svg) no-repeat 90% 55% !important;
  }

  .mt-9 {
    margin-top: 9px;
  }
  .item-level-tag .btn-item-edit {
    background: url(../../../images/edit-icon.svg) no-repeat 35% center;
  }

  .navbar-dark .navbar-toggler-icon {
    background: url(../../../images/hamburger-menu.svg) no-repeat !important;
  }

  .rtn-page-title {
    ol.breadcrumb {
      padding: 5px;
      float: left;
    }
  }

  .advanced-search {
    width: 100% !important;
    margin-left: 0;
    .dropdown-menu.show {
      width: auto;
    }
  }

  .rtn-card-icons {
    margin-top: 15px;
  }

  .greetings-msg {
    padding: 15px !important;
    h3 {
      font-size: 18px;
    }
    h5 {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  .dashboard-menu ul li .dashboard-menu-tile {
    height: 150px;
  }

  .item-level-tag {
    .form-group {
      margin-bottom: 0;
    }
  }

  .item-level-tag input.form-control {
    width: 100%;
  }

  .item-level-tag {
    button {
      margin-top: 10px;
      margin-left: 0px;
    }
  }

  .rtn-card-tiles .card-content {
    height: auto;
  }

  .cbl-text {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .card-parent-group {
    margin-bottom: 15px;
  }

  .rtn-search-results {
    height: max-content;
  }

  .mobile-text {
    display: inline-block;
  }

  .desktop-text,
  .dashboard-hide {
    display: none;
  }

  .margin-navbar {
    margin-top: 10px;
    margin-left: -23px;
  }

  .user-profile-dropdown {
    background: none;
    border: none;
    position: relative;
    padding-right: 15px;
  }

  .fr {
    float: right !important;
  }

  .pr-10 {
    padding-right: 5px;
  }

  .user-profile-align {
    position: absolute;
    transform: translate3d(-180px, 54px, 0px) !important;
    top: 0px;
    left: -39px !important;
    will-change: transform;
  }

  .despatch-search {
    width: 100%;
    margin-left: 0;
  }

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 350px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .modal.left .modal-content,
  .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .modal.left .modal-body,
  .modal.right .modal-body {
    padding: 15px 15px 80px;
  }

  .view-details {
    border-radius: 15px;
    width: 90%;
  }

  .position-loader {
    position: fixed !important;
    left: 35%;
    top: 50%;
  }

  .input-border {
    height: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-color: white;
    border-right-color: white !important;
    font-size: 12px;
    &:focus {
      border-right-color: white !important;
    }
  }

  .clear-height {
    height: 40px;
  }

  .clear-border {
    border-left-color: white;
    border-right-color: white;
    border-top-color: #ced4d9;
    border-bottom-color: #ced4d9;
    padding-right: 0px;
    padding-left: 0px;
    &:hover {
      border-left-color: white;
      border-top-color: #ced4d9 !important;
      border-bottom-color: #ced4d9 !important;
      border-right-color: white;
    }

    .user-profile-dropdown {
      background: none;
      border: none;
      position: relative;
      padding-right: 15px;
    }

    .fr {
      float: right !important;
    }

    .pr-10 {
      padding-right: 5px;
    }

    .user-profile-align {
      position: absolute;
      transform: translate3d(-180px, 54px, 0px) !important;
      top: 0px;
      left: -39px !important;
      will-change: transform;
    }

    .despatch-search {
      width: 100%;
      margin-left: 0;
    }

    .modal.left .modal-dialog,
    .modal.right .modal-dialog {
      position: fixed;
      margin: auto;
      width: 350px;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }

    .modal.left .modal-content,
    .modal.right .modal-content {
      height: 100%;
      overflow-y: auto;
    }

    .modal.left .modal-body,
    .modal.right .modal-body {
      padding: 15px 15px 80px;
    }

    .view-details {
      border-radius: 15px;
      width: 90%;
    }

    .position-loader {
      position: fixed !important;
      left: 35%;
      top: 50%;
    }

    .input-border {
      height: 40px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left-color: white;
      border-right-color: white !important;
      font-size: 12px;
      &:focus {
        border-right-color: white !important;
      }
    }

    .clear-height {
      height: 40px;
    }

    .clear-border {
      border-left-color: white;
      border-top-color: #ced4d9 !important;
      border-bottom-color: #ced4d9 !important;
      border-right-color: white;
      background-color: white !important;
      outline-color: transparent !important;
    }
  }

  .barcode-scanner-border {
    border-left-color: transparent;
    border-top-color: #ced4d9 !important;
    border-bottom-color: #ced4d9 !important;
    border-right-color: #ced4d9 !important;
    height: 40px;
    padding-right: 5px;
    padding-left: 5px;
    &:hover {
      border-left-color: white;
      border-top-color: #ced4d9 !important;
      border-bottom-color: #ced4d9 !important;
      border-right-color: #ced4d9 !important;
    }
  }

  .barcode-scanner-width {
    width: 22px;
  }

  .width-input {
    width: 100%;
  }
  .input-group-mt {
    margin-top: 0px;
  }
  .input-margin {
    margin-top: 8px;
    margin-left: 7px;
    max-width: 100%;
  }

  .home-bc-padding {
    padding-left: 10px !important;
  }

  .no-items-found-despatch {
    position: relative;
    left: 25%;
    margin-top: 10px;
  }

  .no-items-found-despatch-modify {
    position: relative;
    left: 25%;
    margin-top: 10px;
  }
  .summary-modal {
    .modal-dialog {
      width: 350px !important;
    }
    .sm-mb-3 {
      margin-bottom: 1rem !important;
    }
  }

  .dashboard-mobile {
    display: block;
    background-color: #f8f8f8;
  }
  .dashboard-mobile ul {
    margin: 0;
    padding: 0;
  }

  .dashboard-mobile ul li {
    display: block;
    padding: 25px;
    border-bottom: 1px solid #ccc;
    font-weight: 500;
    font-size: 1.1rem;
    cursor: pointer;
  }

  .dashboard-mobile ul li i {
    float: right;
    color: #0073bb;
  }
  .input-margin {
    margin-top: 8px;
    margin-left: 7px;
    max-width: 100%;
  }

  .home-bc-padding {
    padding-left: 10px !important;
  }

  
  .item-detag-card-content {
    border: 1px solid rgba(151, 151, 151, 0.3);
    box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
   // height: 250px !important;
    border-radius: 8px;
    hr {
      margin: 0.5rem 0;
    }
  }

  .item-retag-card-content {
    border: 1px solid rgba(151, 151, 151, 0.3);
    box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
   // height: 210px !important;
    border-radius: 8px;
    hr {
      margin: 0.5rem 0;
    }
  }

  .no-items-found-despatch {
    position: relative;
    left: 25%;
    margin-top: 10px;
  }

  .no-items-found-despatch-modify {
    position: relative;
    left: 25%;
    margin-top: 10px;
  }

  .no-items-found-shipment {
    position: inherit !important;
  }
  .summary-modal {
    .modal-dialog {
      width: 350px !important;
    }
    .sm-mb-3 {
      margin-bottom: 1rem !important;
    }
  }

  .complete-shipment-btn {
    width: 100% !important;
    padding: 0px;
    margin: 0px;
    margin-top: 5px !important;
  }

  .edit-btn {
    font-size: 12px !important;
    margin-right: 9px;
  }

  #shipment_component {
    .form-group {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 5px;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .view-manifest-btn {
      width: 100px !important;
      padding: 0px !important;
      margin: 0px;
      margin-right: 2px;
    }

    .addbtnc {
      margin: 5px 0 5px 10px;
    }

    .mftText {
      float: inherit !important;
      display: block;
      margin-top: 5px;
      border-top: 1px solid #ddd;
      padding: 8px;
      text-align: left;
      margin-right: 0px;
    }

    .card-sect2_cr {
      height: auto;
    }

    .add {
      margin-left: 16px;
    }
    .btn-item-edit {
      background: url(/static/media/edit-icon.e0e69645.svg) no-repeat 30% center !important;
    }
    .footer {
      position: relative;
    }

    .Select-form {
      width: 100% !important;
    }

    .pl24 {
      padding-left: 0px;
    }

    .text-form {
      width: 100% !important;
    }
    .complete-shipment-btn {
      padding: 5px;
    }
    .print-manifest-btn {
      width: 98px !important;
      padding: 0 !important;
    }

    .cbl-container {
      height: auto;
    }

    .modify-form-input {
      width: 100% !important;
    }

    .form-group {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 5px;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .view-manifest-btn {
      width: 49% !important;
      padding: 0px !important;
      margin: 0px;
      margin-right: 2px;
    }

    .addbtnc {
      margin: 5px 0 5px 10px;
    }

    .mftText {
      float: inherit !important;
      display: block;
      margin-top: 5px;
      border-top: 1px solid #ddd;
      padding: 8px;
      text-align: left;
      margin-right: 0px;
    }

    .card-sect2_cr {
      height: auto;
    }

    .add {
      margin-left: 16px;
    }

    .Select-form {
      width: 100% !important;
    }

    .pl24 {
      padding-left: 0px;
    }

    .text-form {
      width: 100% !important;
    }
    .complete-shipment-btn {
      padding: 5px;
    }
    .exit-manifest-btn {
      width: 49% !important;
      padding: 0 !important;
    }
    .print-manifest-btn {
      width: 49% !important;
      padding: 0 !important;
    }

    .cbl-container {
      height: auto;
    }

    .modify-form-input {
      width: 100% !important;
    }
  }

  .of-y {
    overflow-y: auto;
    padding: 10px 10px 0px 10px !important;
  }

  .device-align-reason {
    margin-left: 12px;
  }

  .device-detag-btn {
    margin-left: 30% !important;
  }

  .datePicker-heading{
    width:100% !important;
 
  }

  .search-error {
    padding-left: 0px;
  }

  .collapse-group-btn {
    z-index: 0;
  }

  .d-flex-align {
    width: 100%;
    display: block;
    margin-top: 10px;
  }

  .date-device {
    width: 50px;
    margin-top: 10px;
  }
  #OrderLookUp{

  .multiSelectContainer,.multi-select{
    width: 235px!important;
   
  }
}
  

  #datePeriod {
    .react-datepicker-popper[data-placement^="bottom"] {
      transform: translate(8px, 45px) !important;
    }
    .react-datepicker-popper[data-placement^="top"] {
      transform: translate(-37px, -66px) !important;
    }
  }

  #datePeriodReturn {
    .react-datepicker-popper[data-placement^="bottom"] {
      transform: translate(-37px, 77px) !important;
    }
    .react-datepicker-popper[data-placement^="top"] {
      transform: translate(-37px, -66px) !important;
    }
  }

  .d-m-0 {
    margin: 0;
  }

  .d-mt-10 {
    margin-top: 10px;
  }

  .status-dropdown {
    margin-left: 12px;
  }

  #dcdespatch_component {
    .multiSelectContainer,.multi-select{
      width: 100%!important;
     
    }

    .dropdown-heading{
      background: url(../../../images/down-arrow-icon-black.svg) no-repeat 95% center;
    }

    .w-35{
      width: 35%;
    }

    .w-60{
      width: 60%;
    }
  }
}
