.dcdespatch-header {
  background-color: #f8f8f8;
  padding: 15px;
  border-bottom: 1px solid #ccc;
  font-family: 'open_sanssemibold';
  p {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }
}

.search-container {
  padding: 20px;
}

.search-wrapper {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  section {
    margin-bottom: 15px;
  }
  p {
    font-weight: 500;
    margin-bottom: 15px;
  }
}

.dcdes-datepicker {
  background: url(../../images/calendar-icon.svg) no-repeat 5% center !important;
  padding-left: 35px;
  font-size: 14px;
}

.dcdespatch-search .btn-outline-secondary:hover {
  background: none;
  cursor: none;
  border-left: none;
}

.search-btn {
  text-align: center;
  button {
    background-color: #333;
    width: 30%;
  }
  .btn-primary:hover {
    background-color: #333;
  }
}

.search-by-supplier {
  position: relative;
  width: 100%;
}

.supplier-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  width: inherit;
  margin-top: 40px;
  ul {
    margin: 0;
    padding-left: 10px;
  }
  li {
    display: block;
    margin-bottom: 10px;
  }
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}

.search-results {
  padding: 0 15px;
}

.carrier-reference {
  p:first-child {
    font-size: 16px;
    margin: 0px;
  }
  :nth-child(2) {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 16px;
  }
}

.manifest-number {
  padding-left: 10px;
  padding-bottom: 10px;
  font-family: 'open_sanssemibold';
  p:first-child {
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 0px;
   color:#777;
  }
  :nth-child(2) {
 
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 13px;
  }
}

.myer-logo-manifest {
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-top: 20px;
  .logo-manifest-details {
    background-color: #cdeff9;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    img {
      padding: 15px;
    }
  }
}

.logo-manifest-right {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
  span {
    margin-top: 10px;
    float: left;
    margin-right: 15px;
    font-weight: 500;
  }
}

.barcode-manifest {
  float: right;
  p:first-child {
    margin: 0px;
    font-weight: 500;
  }
  p:nth-child(2) {
    margin: 0px;
  }
}

.location-carrier-details {
  background-color: #f8f8f8;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  div {
    padding: 15px;
  }
  .loc-car-text {
    float: left;
    width: 30%;
    font-weight: 500;
  }
}

.manifest-full-info {
  margin-top: 10px;
  div {
    border: 1px solid #ccc;
    border-radius: 15px;
  }
  table {
    margin-bottom: 0px;
  }
  table th {
    border-top: none;
    border-right: 1px solid #ccc;
  }
  table th:last-child {
    border-right: none;
  }
  table td {
    border-top: none;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: #777;
  }
  table td:last-child {
    border-right: none;
  }
  table tr:last-child td {
    border-bottom: none;
  }
}

.button-container {
  background-color: #f8f8f8;
  text-align: center;
  padding: 20px;
  .exit-manifest-btn {
    width: 45%;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    padding: 15px;
  }
  .close-manifest-btn {
    width: 45%;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    padding: 15px;
    margin-left: 15px;
    i {
      margin-right: 10px;
    }
  }
  .close-manifest-btn:disabled{
    background-color: gray;

  }
}

.manifest-dropdown {
  position: relative;
  display: inline;
  .open-closed {
    width: 32% !important;
  }
  .select-open {
    width: 68% !important;
  }
}

.download-print-container {
  text-align: right;
  margin-top: 10px;
}

.print-manifest-btn,
.view-manifest-btn {
  margin-right: 10px;
  border: 1px solid #ccc;
  height: 35px !important;
  color: #3a3a3a !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  background: none !important;
  border-radius: 6px;
}

.manifesterror-modal {
  p:first-child {
    font-weight: 600;
    font-size: 18px;
  }
  p:nth-child(2) {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0px;
  }
}
