.section-one{
    background-color: #fff;
    padding-top: 10px;
}
.bold-txt{
  font-weight:600;
}
.font12{
    font-size: 12px;
}
.pd10{
    padding: 10px;
}
.grey{
    color: #b6b6b6;
}
.font8{
    font-size: 8px;
}
.font10{
    font-size: 10px;
}
.section-two{
    background-color: #fff;
    //padding-top: 10px;
    margin-top: 10px;
    border-radius: 10px;
    padding-bottom: 10px;
}

.mt10 {
    margin-top: 10px;
}

.mt15{
    margin-top: 15px;
}
.section-three{
    background-color: #fff;
    padding-top: 10px;
    margin-top: 10px;
    border-radius: 10px;
    // margin-bottom: 20px;
}
.search{
    margin-top: 7px;
    margin-bottom: 12px;
    width: 45%;
}
.pd12{
    padding: 12px;
}
// .user-icon{
//     height: 30px;
//     width: 30px;
// }
.user-image-cont{
    padding-bottom: 10px;
    border-bottom: 1px solid;
    border-color: #e0e0e0;
}
.silver{
    color: #b6b6b6;
    position: relative;
    left: -4.7rem;
    font-size: 10px;
    top: 7px;
}
.user-name{
    position: relative;
    top: -6px;
}
.clink{
  
    padding-top: 0.5rem;
    padding-right: 3rem;
    padding-bottom: 0.5rem;
    padding-left: 0 !important;
}
.nav{
    margin-bottom: 10px;
}
.clink.active{
    border-bottom-color: #ff6d54 !important;
    color: #ff6d54 !important;
  //  border:none !important;
  border-top:none !important;
  border-left: none !important;
  border-right: none !important;

}
.clink:hover, .clink:focus{
    border-top:none !important;
    border-left: none !important;
    border-right: none !important;
}
.nav-item .nav-link{
    color: black ;
}
.tab-content > .active{
    display: contents !important;
}
.card-content{
    padding-top: 6px !important;
    padding-right: 1.25rem;
    padding-bottom: 5px!important;
    padding-left: 12px !important;
}
.f12{
    font-size: 12px;
}
.f14{
    font-size: 14px;
}
.dark-grey{
    color: #818181;
}
.bold{
    font-weight: bold;
}
.red{
    color: #ff4000;
}
.separator{
    border: 1px solid;
    border-color: #e8e8e8;
}
.card-columns{   
    margin-top: 15px;
}
.mb15{
    margin-bottom: 15px;
}
.barcode{
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom-color: #e8e8e8;
    margin-left: 12px;
    padding-bottom: 4px;
}
.sbbtn{
    border: 1px solid;
    border-color: #ff4000 !important;
    background: #ff4000 !important;
    color: #fff !important;
}
.inpsearch{
    height: 30px;
}
.p-button{
    margin-left: 7px !important;
    background-color: #fff !important;
    border-color: #e6e6e6 !important;
    color: #bfbfbf !important;
    height: 33px !important;
}


/* Declare some variables */
$base-color: #ced4da;
$light-background: lighten(desaturate($base-color, 50%), 12.5%);

.current-page {
  font-size: 1.5rem;
  vertical-align: middle;
}

.country-card-container {
  height: 60px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.country-name {
  font-size: 0.9rem;
}

.country-region {
  font-size: 0.7rem;
}

.current-page,
.country-name,
.country-region {
  line-height: 1;
}

// Override some Bootstrap pagination styles
ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  li.page-item.active {
    a.page-link {
      color: saturate(darken($base-color, 50%), 5%) !important;
      background-color: saturate(lighten($base-color, 7.5%), 2.5%) !important;
      border-color: $base-color !important;
    }
  }

  a.page-link {
    padding: 0.75rem 1rem;
    min-width: 3.5rem;
    text-align: center;
    box-shadow: none !important;
    border-color: $base-color !important;
    color: saturate(darken($base-color, 30%), 10%);
    font-weight: 900;
    font-size: 1rem;

    &:hover {
      background-color: $light-background;
    }
  }
}