/* You can add search order styles to this file, and also import other style files */

.topCenter{
    position: absolute;
    top: 8px;
    right: 40%;
    width:350px;
    
   
  }
  .react-datepicker__close-icon::after{
    content: url(../../images/clear-icon.svg);
    background-color:white !important;

  }
  #toast
  {
    border-radius: 5px;
    min-width: max-content;
    background-color :white;
  }
  img.tickmark
  {
      margin-top: 10px;
  }
  .toast__content{
    padding-left:10px;
    padding-right:10px;
    margin-left: 3%;
    margin-top:1%;
    margin-bottom: 1%;
    min-width: 330px;
    height:60px;
    
    
  }
  .toast__type {
    color: #3e3e3e;
    font-weight: 700;
    font-size: 16px;
    font-family: 'open_sanssemibold';
    color:rgba(89,173,41,255);
    margin-top: 0;
    margin-bottom: 8px;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .tickmark
  {
    margin-left:1%;
    float:left; 
    height:30px;
    margin-right:10%;
    text-align: center; 
  }
  
  .toast__message {
      
      margin-top: 0;
      font-size: 12px;
        font-family: 'open_sanssemibold';
    margin-bottom: 0;
      color: #878787;
  }
  
.bg-grey {
  background: $bg-grey;
}

.bg-white {
  background: $white;
}

.logo-sidemenu {
  margin: 0 auto;
  text-align: center;
  display: block;
}

.padding-reset {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sidebar {
  padding: 20px 0;
  height: 100vh;
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
}

.customer-name {
  h5 {
    font-size: 16px;
    font-family: "open_sanssemibold";
    margin-bottom: 0px;
  }

  .cust-membercard {
    font-size: 12px;
    color: #c0c0c0;
    margin-bottom: 0;
  }
}

.returns-mainmenu {
  padding: 30px 0;

  li {
    padding: 5px 0;

    a {
      font-size: 14px;
      padding: 20px;
      display: block;

      &.active {
        background: $black;
        color: $white !important;
      }
    }
  }
}

.rtn-page-title {
  background: $white;
  margin-left: -15px;
  margin-right: -15px;
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  padding: 0 10px;

  h4 {
    font-size: 14px;
    font-family: inherit;
    margin-bottom: 0;
    padding: 5px 0;
    font-weight: 700;
  }

  .breadcrumb {
    float: right;
    padding: 10px 0 0;
    font-size: 11px;
  }
}

.search-panel,
.rtn-search-results {
  background: $white;
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  border-radius: 12px;
  padding: 10px;
}

.rtn-search-results-cbldispatch {
  background: $white;
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  border-radius: 12px;
  padding: 10px;
}

hr {
  margin: 0.5rem 0;
}

.search-panel {
  label {
    font-size: 13px;
    font-family: "open_sanssemibold" !important;
    //padding: 15px 10px 15px;
  }
}

.search-panel {
  padding: 5px 10px !important;
}

.rtn-search-results {
  min-height: 300px;
  overflow: hidden;
  margin-bottom: 1%;
}

.hr-divider {
  border: 1px solid rgba(151, 151, 151, 0.3);
}

.rtn-card-icons {
  text-align: right;
  margin-bottom: 0px;

  li {
    display: inline-block;
    border: 1px solid #dbdbdb;
    padding: 5px 10px;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }
  }
}

.card-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 10px !important;
}

.despatch-outline {
  border-top: 1px solid rgba(151, 151, 151, 0.3) !important;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3) !important;
  border-left-color: $white !important;

  &:hover {
    background: $brand-primary-color;
    border: 1px solid rgba(151, 151, 151, 0.3);
  }
}

.despatch-input {
  font-size: 13px !important;
  margin-top: 2.5px !important;
}

.rtn-card-tiles {
  .card-content {
    border: 1px solid rgba(151, 151, 151, 0.3);
    box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
    height: 157px;
    border-radius: 8px;

    hr {
      margin: 0.5rem 0;
    }
  }
}

.item-level-tag {
  input.form-control {
    width: 100%;
  }

  button {
    width: 100%;
    height: 32px;
    font-size: 12px;
  }
}

.pr-0 {
  padding-right: 0px !important;
}

.err {
  border: 1px solid red;
  border-radius: 8px;
}

.err {
  border-color: red;
}

.of-y {
  overflow-y: auto;
  padding: 10px 10px 0px 10px !important;
}

.search-panel-ro {
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  border-radius: 12px;
  padding: 20px 10px 20px 10px;
}

.advanced-search {
  width: 450px !important;
  margin-left: 15px;

  input.input-search {
    border: 1px solid rgba(151, 151, 151, 0.3);

    //border-right: 0;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    padding-left: 50px;
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  input.input-search-dcitem {
    border: 1px solid rgba(151, 151, 151, 0.3);

    //border-right: 0;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    padding-left: 50px;
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  input.errtext {
    color: red;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .btn-outline-secondary {
    border-top: 1px solid rgba(151, 151, 151, 0.3);
    border-bottom: 1px solid rgba(151, 151, 151, 0.3);
    border-left-color: $white;

    &:hover {
      background: $brand-primary-color;
      border: 1px solid rgba(151, 151, 151, 0.3);
    }
  }

  .btn-filter-border {
    border-right: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 0 8px 8px;
  }

  .dropdown-menu.show {
    transform: translate3d(0px, 48px, 0px) !important;
    width: 800px;
    z-index: 1;
    will-change: inherit !important;
  }
}

.error {
  color: red;
  padding-left: 2%;
}

.reportdate {
  display: inherit;
  background: url(../../images/calendar-icon.svg) no-repeat 97% center !important;
}

.datePicker {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.search-error {
  padding-left: 10px;
  color: red;
  margin-top: 5px;
  display: block;
  width: 100%;
}

.d-inline {
  display: inline;
}

.date-error {
  margin-left: 46px;
  display: flex !important;
}

.search-error-hide {
  padding-left: 10px;
  color: red;
  margin-top: 5px;
  display: block;
  width: 100%;
  visibility: hidden;
}

.card-body-summary {
  background-color: #f7f8fc;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 10px;
  padding: 10px 10px 10px 15px;

  h5 {
    font-size: 14px;
    margin: 0 auto;
    font-weight: 600;
  }

  h6 {
    font-size: 12px;
    font-family: "open_sansregular";
    margin-top: 5px;
    margin-bottom: 20px;
    overflow-wrap: break-word;
  }
}

.view-summary-align {
  max-height: 60px;
  padding: 15px 15px 25px !important;
}

.expand-All {
  font-family: "open_sanssemibold";
  font-size: 14px;
}

.datePicker-heading {
  width:100%;
}

.d-flex-align {
  display: flex;
}

.advance-search-error {
  font-family: "open_sanssemibold";
  font-size: 10px;
  color: #ff4000;
  padding-top: 4px;
}

.advance-search-header {
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 10px;
}

#search_screen {
  .card-body {
    background-color: #f7f8fc;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    padding-top: 0px;
  }
}

.search-date-width {
  width: 125px !important;
}

.status-dropdown {
  width: 100% !important;
  font-size: 14px;
  height: 40px;
}

.collapse-group-btn {
  width: 200px;
  text-align: left;
  padding-left: 40px;
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 10% center;
  color: black;
  font-size: 14px;
  text-decoration: none;
  font-family: "open_sanssemibold";

  &:hover {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
}
.searchWrapper {
  z-index: 1;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

#datePeriod {
  .react-datepicker-popper[data-placement^="bottom"] {
    transform: translate(-10px, 45px) !important;
  }
}

.advance-search-dropdown {
  background-color: white !important;
  color: black !important;
  width: 162px !important;
  padding-right: 30px;
  text-align: left !important;
  height: 33px !important;
  border-color: #ced4da !important;
  text-decoration-color: white !important;
  font-size: 12px;
  font-weight: 100;
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
}

#datePeriodReturn {
  .react-datepicker-popper[data-placement^="bottom"] {
    transform: translate(-38px, 77px) !important;
  }
  .react-datepicker-popper[data-placement^="top"] {
    transform: translate(-38px, -49px) !important;
  }
}

.react-datepicker__day--outside-month {
  opacity: 0;
  pointer-events: none;
}

.react-datepicker {


  left: 20px;

  .react-datepicker__header {
    background-color: #ff401a !important;
    // background-color: #f71aff  !important;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: $white;
  }

  .react-datepicker__navigation--next {
    border-left-color: $black;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $black;
  }

  .react-datepicker__day--keyboard-selected{
    background-color: white;
    color: black;
  
  }
  .react-datepicker__day--in-range
  {
    background-color: #216ba5;
    color: white;
    border-radius:0.3rem;

  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)
  {
    background-color:rgba(33, 107, 165, 0.5);
    color: white;

  }
  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end
  {
    background-color: #FF401A !important;
    color: white !important;

  }
}

.advanced-search {
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    background: $brand-primary-color;
    border-color: none;
  }
}

.rtn-search-attr {
  border: 1px solid rgba(151, 151, 151, 0.3);
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  margin-top: -2%;

  ul {
    margin-bottom: 0;
  }

  .form-group {
    margin-top: 10px;

    label {
      font-size: 13px;
      font-family: "open_sansregular";
      margin-bottom: 5px;
    }

    .form-control {
      width: 100%;
      height: 40px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      font-size: 13px;
    }

    select.form-control {
      font-size: 13px;
    }
  }
}

.rtn-clear-input {
  .btn-outline-secondary {
    &:hover {
      background: none;
      border-left-color: white;
    }
  }
}
.dropdown-content{
  z-index:5!important;
  font-size: 11px !important;
  font-family: 'open_sansregular' !important;
  padding-top: 3px !important;

}

.rmsc *
{
  transition:all 0.0s ease !important;
}
.select-item
{
  background-color: white !important;
  font-size: 12px !important;
  font-family: 'open_sansregular' !important;
  padding-top:5px !important ;
  padding-bottom: 5px !important;
}
.select-item:hover{
  background-color: #0096fb !important;
  color:white;
}
.button-group {
  padding: 10px 0 10px;

  .btn {
    width: 120px;
    height: 35px;
    font-size: 13px;
    font-family: "open_sanssemibold";
    border-radius: 8px;
  }

  .rtn-search {
    background: $black;
    color: $white;
    text-decoration: none;
  }
}

.rtn-link {
  color: $black !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.pt-10 {
  padding-top: 10px;
}

.no-item-found {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .view-details {
    border-radius: 15px;
    width: 50%;
  }
  .react-datepicker{
    left:20px;
  }
  .rtn-search-results {
    min-height: 70vh;
  }

  .rtn-search-results-cbldispatch {
    display: flex;
    flex-direction: column !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.multiSelectContainer,.multi-select{
  width: 160px!important;
  background-color: white;
}


.searchWrapper{
  height: 40px;
  overflow: clip !important;
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
}
.icon_down_dir{
  display: none;
}

.rtn-search[disabled] {
  opacity: 0.5;
}

.barcode-image {
  background: url(../../images/barcode-thin.svg) no-repeat 98% center;
  padding-right: 30px;
  background-color: white !important;
}
.searchWrapper{
 width:100%;


}
.options{
  padding-top: 10px;
  font-size: 11px !important;
  font-family:'open_sansregular' !important;

}
.gray{
  color:#757575 !important;
}

.chip {
  margin-bottom: 10px !important;
  border-radius: 2px;
  color: black;
  overflow: hidden;
  width:80%;
 
  padding: 3px 10px;
  background-color: white !important;
  font-family: 'open_sansregular';
  font-size: 12px;
}
.dropdown-heading-dropdown-arrow,.clear-selected-button{
  
  display: none !important;
}
.dropdown-heading-value{
  height: 100%;
  padding-top:13px;
  font-size: 13px;

}
.searchorderSearch{
  width: 95px;
  margin-top: 2px !important;
  height: 35px;
  margin:0px;
  margin-left: 20px;
  border-radius: 8px;
  background: #ff3d1b;
  color: #fff;
  display: inline;


}
.searchorderSearch:disabled{
  background: #f2725b ;
  color: rgb(223, 223, 223);
}
.dropdown-container:focus-within{
  box-shadow: none !important;
  border-color:var(--rmsc-border) !important;
}
.dropdown-heading{
  padding-right: 30px !important;
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
 
  font-family: 'open_sansregular';
}
.react-datepicker__close-icon{
  margin:0px 30px 0px 0px;
}

#search_input,#date{
  caret-color: transparent;
  cursor: pointer;
}
.optionListContainer{
  font-family: 'open_sansregular';
  font-size: 12px;
  z-index:5 !important;
}

#class_grp
{
  input[type=checkbox]{
    display: none;
  }
  .select-item:focus{
    background-color: #0096fb !important;
    color:white;
  }
}
