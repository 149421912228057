.dispatch-scanner-img {
  position: relative;
  height: 25px;
  width: 25px;
  right: 4.5em;
  top: 7.5px;
}
.cbl-scanner-img {
  position: relative;
  height: 25px;
  width: 25px;
  right: 2.5em;
  top: 7.5px;
}

.mb-20{
  margin-bottom: 20px;
}

.dispatch-clear-img {
  position: relative;
  height: 20px;
  width: 25px;
  right: 6em;
  top: 11px;
}
.dispatch-select-input {
  width: 8% !important;
  margin-left: 10px;
  height: 42px !important;
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  &:focus {
    background-color: #ff4000;
    color: white;
  }
}

.dispatch-search-input {
  height: 42px !important;
  width: 32% !important;
  margin-left: 0px;
  padding-left: 20px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.dispatch-search-input-error {
  height: 42px !important;
  width: 32% !important;
  margin-left: 0px;
  padding-left: 20px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-color: red !important;
  box-shadow: none !important;
}

.despatch-error {
    color: red;
    margin-top: 5px;
    display: block;
    width: 100%;
}

.inpsearch {
  height: 32px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  width: 300px;
}
.dispatch-silver {
  color: #7a7a7a;
  position: relative;
  font-size: 10px;
  top: 10px;
  right: 6em;
}
.active.clink {
  font-weight: 500;
}
.clink {
  font-weight: 400 !important;
}
.dataview-demo .p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.dataview-demo .product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.dataview-demo .product-description {
  margin: 0 0 1rem 0;
}

.dataview-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.dataview-demo .product-category {
  font-weight: 600;
  vertical-align: middle;
}

.dataview-demo .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.dataview-demo .product-list-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}

.dataview-demo .product-list-item .product-list-detail {
  flex: 1 1 0;
}

.dataview-demo .product-list-item .p-rating {
  margin: 0 0 0.5rem 0;
}

.dataview-demo .product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  align-self: flex-end;
}

.dataview-demo .product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}

.dataview-demo .product-list-item .p-button {
  margin-bottom: 0.5rem;
}

.dataview-demo .product-grid-item {
  margin: 0.5em;
  border: 1px solid #dee2e6;
  padding: 2rem;
}

.dataview-demo .product-grid-item .product-grid-item-top,
.dataview-demo .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataview-demo .product-grid-item img {
  width: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 2rem 0;
}

.dataview-demo .product-grid-item .product-grid-item-content {
  text-align: center;
}

.dataview-demo .product-grid-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .view-details {
    border-radius: 15px;
    width: 50%;
  }
  .dataview-demo .product-list-item {
    flex-direction: column;
    align-items: center;
  }

  .dataview-demo .product-list-item img {
    width: 75%;
    margin: 2rem 0;
  }

  .dataview-demo .product-list-item .product-list-detail {
    text-align: center;
  }

  .dataview-demo .product-list-item .product-price {
    align-self: center;
  }

  .dataview-demo .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .dataview-demo .product-list-item .product-list-action {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
.card-columns.dispatch-card-column {
  column-count: 3 !important;
}
.dispatch-item-tag-button {
  border: 1px solid;
  border-color: #ff4000 !important;
  background: #ff4000 !important;
  color: #fff !important;
  height: 35px !important;
  margin-top: 19px;
}
.dispatch-item-separator {
  border: 1px solid;
  width: 100%;
  height: 1px;
  margin-top: 1em;
  border-color: #e0e0e0;
}
.dispatch-item-tag-img {
  position: relative;
  height: 25px;
  width: 25px;
  left: 10em;
  bottom: 2em;
}
.modify-dispatch-item-tag-button {
  border: 1px solid;
  border-color: #ff4000 !important;
  background: #ff4000 !important;
  color: #fff !important;
  height: 35px !important;
  margin-top: 19px;
  width: 58% !important;
  margin-left: -7px;
}
.ml-8p {
  margin-left: 8px;
}

.ml-20 {
  margin-left: -20px !important;
}
.modify-dispatch-tab.active {
  position: relative;
  background-color: #000000 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  width: 150px !important;
  text-align: center !important;
}
.modify-dispatch-tab {
  background-color: #858585 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  width: 150px !important;
  text-align: center !important;
}
.modify-tabs {
  border-bottom: none !important;
}
.modify-dispatch-tab:nth-child(2) {
  background: red;
}
.edit-modal-btn {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 106px !important;
}
.modify-dispatch-barcode {
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom-color: #e8e8e8;
  margin-left: 0px;
  padding-bottom: 0px;
  width: 79%;
  font-size: 10px;
}
.dispatch-item-tag-img1 {
  position: relative;
  height: 22px;
  width: 22px;
  bottom: 0em;
  left: -2em;
}
.dispatch-modify-edit-btn {
  margin-left: -3.7em !important;
}
.modify-dispatch-detag-barcode {
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom-color: #e8e8e8;
  margin-left: 0px;
  padding-bottom: 0px;
  width: 100%;
  font-size: 10px;
}
.dispatch-item-detag-img1 {
  position: relative;
  height: 22px;
  width: 22px;
  bottom: 0em;
  left: 94%;
  top: -2.3em;
}

.mt-10 {
  margin-top: 10px;
}

.item-detag-button {
  margin-top: 5px;
  margin-left: 10px;
  width: 30% !important;
  background: #ff4000 !important;
  color: #ffffff !important;
  border-radius: 5% !important;
  border: 0 !important;
}
.item-detag-button:disabled{
  opacity: 0.5;
}

.item-detag-card-content {
  border: 1px solid rgba(151, 151, 151, 0.3);
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  //height: 200px !important;
  border-radius: 8px;
  hr {
    margin: 0.5rem 0;
  }
}

.item-retag-card-content {
  border: 1px solid rgba(151, 151, 151, 0.3);
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
 // height: 200px !important;
  border-radius: 8px;
  hr {
    margin: 0.5rem 0;
  }
}

.pt-15 {
  padding-top: 15px;
}
.dispatch-detag-select-input {
  width: 50% !important;
  border-radius: 10px !important;
  height: 35px !important;
  font-size: 14px !important;
  margin-left: 10px;
  margin-top: 4px;
}
.edit-detag-btn {
  margin-top: 4px !important;
  width: 140px !important;
  text-align: center !important;
  margin-left: -60px !important;
}

.despatch-search {
  width: 250px !important;
}

.d-flex {
  display: flex;
}

.w-7 {
  width: 70% !important;
}

.w-3 {
  width: 30% !important;
}

.pb-15 {
  padding-bottom: 15px;
}

.retag-edit {
  width: 30% !important;
  font-family: "open_sanssemibold";
  background: none;
  border: none;
  color: #0255e1;
}

.text-align-centre {
  text-align: center;
}

.modify-close-align {
  right: 0;
  position: absolute;
  margin-top: 13px;
  padding-right: 10px !important;
  color: white !important;
}

.modify-tabs-bg {
  background-color: #858585;
  width: fit-content;
  border-radius: 70px;
}

.search-option {
  background-color: white;
  color: black;
}

.ml-25 {
  margin-left: 25px;
}

.confirm-yes{
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 200;
    background-color: #ff4000;
    color: white;
    font-family: 'open_sansregular';
    text-decoration: none!important;
}

.confirm-no{
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 500;
    color: black!important;
    text-decoration: none!important;
    &:hover{
        background-color: #ebebeb!important;
    }
}

.bt-none {
  border-top: none;
}

.color-black {
  color: black !important;
}

.position-fixed {
  position: fixed;
}

.position-absolute{
  position: absolute;
}

.barcode-validation-failed-despatch {
  padding: 2px;
  font-family: "open_sanssemibold";
  padding-left: 4px;
  font-size: 12px;
  padding-top: 3px;
  color: #ff4000;
}

.img-with-text {
  text-align: justify;
  margin-top: 100px;
}

.img-with-text img {
  display: block;
  margin: 0 auto;
}

.no-item-found {
  text-align: center;
  color: silver;
}

.dropdown-toggle-despatch::after {
  position: absolute !important;
  right: 7% !important;
  color: white !important;
  top: 20px !important;
}

.sort-button {
  color: black !important;
  text-decoration-color: white !important;
  font-size: 14px;
  font-family: "open_sansregular";
  // background: url(../../images/tick-icon-solid.svg) no-repeat 0% center  ;
}

.sort-title {
  text-align: center;
  font-size: 14px;
}

.sort-tick {
  width: 10px !important;
}

.dropdown-search {
  background-color: white !important;
  color: black !important;
  width: max-content !important;
  padding-right: 30px;
  text-align: left !important;
  margin-top: 0px !important;
  height: 40px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-color: #ced4da !important;
  text-decoration-color: white !important;
  font-size: 12px;
  font-weight: 100;
  &:focus {
    background-color: #ff4000 !important;
    color: white !important;
    text-decoration-color: #ff4000 !important;
    background: url(../../images/down-arrow-icon.svg) no-repeat 90% center;
  }
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
}

.dropdown-button {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  opacity: 0;
  pointer-events: none;
}

.ml-15 {
  margin-left: 15px;
}

.despatch-search-dropdown-align {
  z-index: 1;
  position: absolute !important;
  transform: translate3d(0px, 39px, 0px) !important;
  top: 0px !important;
  left: 0px !important;
  will-change: transform !important;
}

.detag-dropdown {
  background-color: white !important;
  color: black !important;
 // width: 200px !important;
  padding-right: 30px;
  text-align: left !important;
  margin-top: 5px !important;
  height: 33px !important;
  border-color: #ced4da !important;
  text-decoration-color: white !important;
  font-size: 12px;
  font-weight: 100;
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
}

.detag-dropdown-align {
  z-index: 1;
  position: absolute !important;
  transform: translate3d(0px, 34px, 0px) !important;
  top: 0px !important;
  left: 0px !important;
  will-change: transform !important;
}

.input-shadow {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

.cd {
  cursor: default;
}

.p10 {
  padding: 10px 10px 10px 10px;
}

.mw-12 {
  min-width: 12.5rem !important;
}
