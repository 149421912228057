/* You can add layout styles to this file, and also import other style files */

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "open_sansregular";
  font-size: 100%;
  vertical-align: baseline;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  min-width: 320px;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

@-ms-viewport {
  width: device-width;
}

html,
body {
  background-color: #f8f8f8;
  height: 100vh; /* Needed for container's min-height  */
  overflow-x: hidden !important;
}

footer {
  position: relative;
}

html,
body {
  height: 100vh; /* Needed for container's min-height  */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

.App {
  background: $white !important;
  height: 100vh;
}

.container {
  max-width: 1920px !important;
}

header {
  background: $brand-primary-color;
  height: 60px;
}

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.body-background-color {
  background-color: #f8f8f8;
}

.main-menus {
  img {
    height: 50px;
  }
}

.user-role-span {
  font-size: 12px;
  color: white;
}

.user-profile-align {
  position: absolute;
  transform: translate3d(-75px, 54px, 0px) !important;
  top: 0px;
  left: -45px !important;
  will-change: transform;
}

.background-transparent {
  color: white;
  background-color: transparent;
  border-color: transparent;
}

.user-display-name {
  font-size: 14px;
  margin-bottom: -7px;
  color: white;
  margin-top: 6px;
}

.btn:hover {
  text-decoration: underline;
  color: white;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0;
}

.btn.focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0;
}

.margin-dashboard-banner {
  margin-right: 15px;
  margin-left: 15px;
}

.user-profile-dropdown {
  background: none;
  border: none;
  position: relative;
  padding-right: 40px;
}

header {
  .logo {
    padding: 8px 0;
    align-items: center;
  }
  .user-profile {
    a {
      color: $white;
      padding: 7px 3px;
      font-size: 10px;
      margin-top: 7px;
      div {
        border: none;
        font-size: 14px;
      }
    }

    br {
      height: 0%;
    }
  }
}

.dashboard-wrapper {
  padding-top: 20px;
}

.user-profile {
  .username-role {
    h5 {
      font-size: 14px;
      font-family: "open_sanssemibold";
    }
    h6 {
      font-size: 13px;
      font-family: "open_sansregular";
    }
  }
  .user-profile-store {
    font-size: 13px;
    font-family: "open_sansregular";
  }
  .logged-time {
    h6 {
      font-size: 13px;
      font-family: "open_sansregular";
    }
  }

  .btn-logout {
    background: $brand-primary-color;
    color: $white;
    font-size: 13px;
    font-family: "open_sanssemibold";
    text-decoration: none;
    &:hover {
      color: $white;
    }
  }
}

.breadcrumb-color {
  color: black;
}

ol.breadcrumb {
  background: none;
  padding: 20px 10px 0;
  font-size: 13px;
  color: $black;
}
#changeLoc{
  input[type=checkbox]{
    display: none;
  }
}
.dropdown-search_loc {
  background-color: white !important;
  color: black !important;
  width: 200px ;
  padding-right: 30px;
  text-align: left !important;
  margin-top: 0px !important;
  height: 40px !important;
  border-radius: 05px ;
  
  border-color: #ced4da !important;
  text-decoration-color: white !important;
  font-size: 12px;
  font-weight: 100;
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 90% center;
}
.dashboard-banner {
  background: linear-gradient(180deg, #fcecdc 70%, #e78b38 30%);
  border-radius: 8px;
  position: relative;
  .greetings-msg {
    padding: 5px;
    h3 {
      font-size: 18px;
      font-family: "open_sansbold";
    }
    h5 {
      font-size: 14px;
      font-family: "open_sanssemibold";
    }
  }
  img {
    height: 100px;
  }
}

.pr-10 {
  padding-right: 10px;
}
.dashboard-menu {
  ul {
    li {
      text-align: center;
      .dashboard-menu-tile {
        margin-bottom: 15px;
        border-radius: 8px;
        height: 110px;
        width: 100%;
        box-shadow: 0px 10px 40px 0px rgba(196, 196, 196, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.06);
        cursor: pointer;
        &:hover {
          background: #f5f5f5;
        }
        .menu-tile {
          margin: 0 auto;
          i {
            display: block;
            margin-bottom: 15px;
            &.main-menu-icon {
              padding-top: 13px;
              display: block;
            }
          }
        }
      }
    }
  }
}

.main-menu-icon {
  padding-top: 13px;
  display: block;
}

.user-icon {
  padding-top: 5px;
  margin-right: 5px;
}

.dashboard-menu {
  ul {
    li {
      a {
        color: $black;
      }
    }
  }
}

.dashboard-right {
  img {
    margin: 5px 0;
  }
}

.notification-icon {
  background: #eef2fc;
  width: 70px;
  height: 70px;
  border-radius: 12px;
  img {
    text-align: center;
    display: block;
    margin: 0 auto;
    padding: 20px;
  }
}

.profile-icon-p {
  padding-top: 2px;
}

.notifications-tile {
  ul {
    li {
      margin-bottom: 30px;
    }
  }
  .media-body {
    margin-top: 15px;
    h5 {
      font-size: 14px;
      font-family: "open_sanssemibold";
      cursor: pointer;
    }
    p {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.bell-margin {
  padding-top: 10%;
  margin-top: 8px;
  margin-right: -23px;
}

.mobile-text,
.hide {
  display: none;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background: $black;
  span {
    text-align: center;
    display: block;
    font-size: 12px;
    color: $white;
  }

  .fr {
    float: right !important;
  }
}

.mr-logo {
  margin-right: 0.15rem !important;
}

.barcode-icon {
  height: 20px;
}

.view-details-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59px;
  background-color: #fe4001;
}

.error-popup-header-bg {
  background-color: #ff3d1b;
}

.navbar-toggler-icon {
  background-image: url(../../images/hamburger-menu.svg);
}

.dropdown-profile {
  background-color: #ff4000 !important;
  color: white !important;
  text-decoration-color: #ffffff !important;
  background: url(../../images/down-arrow-icon.svg) no-repeat 90% center !important;
}

.dropdown-profile[aria-expanded="true"] {
  background: url(../../images/up-arrow-icon.png) no-repeat 90% center !important;
}

.modal-z {
  z-index: 1100 !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.no-items-found-despatch {
  position: relative;
  left: 45%;
  margin-top: 10px;
}

.no-items-found-despatch-modify {
  position: relative;
  left: 45%;
  margin-top: 10px;
}

@media (min-width: 1599.98px) {
  .margin-ls-profile {
    margin-right: -60px;
  }
  // .user-profile-align{
  //   position: absolute;
  //   //transform: translate3d(-114px, 64px, 0px);
  //   top: 0px;
  //   left: -68px !important;
  //   will-change: transform;
  // }
}

@media (min-width: 1400.98px) {
  .margin-ls-profile {
    margin-right: -70px;
  }
  // .user-profile-align{
  //   position: absolute;
  //   //transform: translate3d(-114px, 64px, 0px);
  //   top: 0px;
  //   left: -68px !important;
  //   will-change: transform;
  // }
  .rtn-search-results {
    min-height: 375px !important;
    overflow: hidden;
    margin-bottom: 1%;
  }

  .view-details {
    border-radius: 15px;
    width: 50%;
  }
}

.add-btn-toggle {
  position: absolute;
  transform: translate3d(7px, 37px, 0px) !important;
  top: 0px;
  left: 0px;
  will-change: transform;
  width: 90%;
  label {
    font-size: 13px;
  }
  input {
    font-size: 12px;
  }

  .translate-summary {
    transform: translate3d(7px, 57px, 0px) !important;
  }
}

.add-btn-toggle-summary {
  position: absolute;
  transform: translate3d(7px, 57px, 0px) !important;
  top: 0px;
  left: 0px;
  will-change: transform;
  width: 90%;
  label {
    font-size: 13px;
  }
  input {
    font-size: 12px;
  }

}
.pie-cart-container
{
margin-bottom: 45px;
border-radius: 8px;
box-shadow: 0px 10px 40px 0px rgba(196, 196, 196, 0.25);
border: 1px solid rgba(151, 151, 151, 0.3);
}
.pie-cart-header
{
padding: 5px;
padding-top: 10px;
border-top-right-radius: 5px;
border-top-left-radius: 5px;
background-color: #f7f8fc;
border: 1px solid rgba(151, 151, 151, 0.3);
}

.carrier-dc-despatch{
  width: 100%;
  color: black;
  background-color: white!important;
  border: 1px solid rgba(151, 151, 151, 0.3);
  text-align: inherit;
  font-size: 12px;
  text-decoration: none;
  background: url(../../images/down-arrow-icon-black.svg) no-repeat 98%;
  height: 35px;
  border-radius: 10px;
}
