
.modal-title-despatch{
    margin-top: 8px;
    //font-weight: 400;
    //font-family: 'open_sansregular';
    color: white;
    padding-left: 20px;
}

.confirmation-style{
    color: white;
    font-weight: normal;
}
.border-none{
    border: none !important;
}
.ctbtn{
    border-radius: 10px !important;
    height: 40px;
}
.box-shadow-none{
    box-shadow: none !important;
}
.bg-none{
    background: none !important;
}


.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 500px;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
       
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}

 
/*Right*/
	.modal.right.fade .modal-dialog {
		right: 0px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.in .modal-dialog {
		right: 0;
	}

/* ----- MODAL STYLE ----- */
	.modal-content {
		border-radius: 0;
		border: none;
	}

    .modal-radius{
        border-radius: 10px!important;
    }

    
	.modal-header-despatch {
        max-height: 60px!important;
		background-color: #ff3d1b;
	}
    .modal-header-despatch-confirmation {
        height: 60px;
		background-color: #ff3d1b;
	}

    .confirmation-header{
        background-color: #646363;
    }

.width2{
    width: 205px;
}
.mt-4p{
    margin-top: 4px;
}
.active-submenu{
    background: #f5f8ff;
    width: 80%;
    border-radius: 10px;
}
.ct-select-option{
    border-radius: 10px !important;
    height: 40px !important;
    width: 35% !important;
    font-size: 12px !important;
}
.ct-text-input{
    border-radius: 10px !important;
    height: 40px !important;
    width: 50% !important;
    font-size: 12px !important;
}
.ml-20{
    margin-left: 20px;
}
.scanner-img1{
    position: absolute;
    height: 30px;
    width: 30px;
    /* left: -53px; */
    right: 26em;
    top: 6px;
}
.no-item{
    text-align: center;
    margin-top: 5em;
}
.edit-btn{
    width: 80px;
    height: 35px !important;
    margin-top: 4px;
    border-radius: 8px;
    color: #fff;
    border-color: #1b76fd;
    background: #fff;
    font-size: 15px;
}
.pl-4p{
    padding-left: 4px;
}
.modify-form-input{
    height: 35px !important;
    width: 386px !important;
    font-size: 12px !important;
}
.separator{
    border: 1px solid;
    width: 100%;
    height: 1px;
    margin-top: 3em;
    border-color: #e0e0e0;
}
.modify-btn{
    width: 80px;
    border-color: #e0e0e0 !important;
    margin-right: 10px;
    border: 1px solid !important;
    height: 35px !important;
    color: #3a3a3a !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
    padding: 9px !important;
    background: none !important;
    border-radius: 6px;
}