#DCReceipting {
  .min-height-cbl {
    min-height: 65vh;
  }
  .opensansemi{
    font-family: "open_sanssemibold";
  }
  .opensanbold{
    font-family: "open_sansbold";
  }
  .cbl_header {
    padding-left: 10px;
    top: 60px;
  }
  .back_arrow {
    float: left;
    padding-right: 10px;
    cursor: pointer;
  }
  .color-blue {
    color: blue;
  }
  .cblName {
    float: left;
    padding-right: 10px;
  }
  .alert-msg {
    padding-left: 20px;
    background-color: antiquewhite !important;
  }
  .total-text {
    text-align: right !important;
    font-size: 14px !important;
  }
  .dcreceipting-header {
    background-color: #f8f8f8;
    padding: 15px;
    font-family:'open_sanssemibold';
  }
  .manifest_check {
    display: flex !important;
    padding: 0px 0px 10px 0px !important;
  }
  .count_container {
    width: 100px !important;
    height: 80px !important;
    box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .w-70 {
    width: 70%;
  }
  .back_button {
    background-color: white;
    color: black;
    height: 30px;
    width: 49%;
    border-radius: 5px;
    border: 1px solid rgba(151, 151, 151, 0.3);
  }
  .pt10 {
    padding-top: 10px;
  }
  .recieve_manifest {
    border: none;
    background-color: black;
    color: white;
    height: 30px;
    width: 150px;
  }
  .recieve_manifest:disabled {
    background-color: gray !important;
  }

  .bg-blue {
    background-color: #cdeff9;
  }
  .manifest_table {
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 5px;
    box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  }
  .count_container_val {
    padding: 12px 10px 10px;
  }
  .disabletextbg {
    background-color: #e8e8e8 !important;
  }
  .LinkUBLCBL {
    text-decoration: none;
    color: black;
  }
  .ifdisable:disabled{
    background-color: gray !important;
  }

  .dcreceipting-header p {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }

  .dcr-fields {
    padding: 15px 0;
    margin: 0 auto;
  }

  .dcr-input-group {
    display: flex;
    flex-direction: column;
  }
  .dcr-input-group p {
    margin-top: 10px;
  }
  .dcr-input-icon-container {
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    background: #fff;
    align-items: center;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
  }
  .dcr-input-group select:focus {
    outline: none !important;
    border: 0px;
  }
  .dcr-input-icon-container input {
    outline: none;
    border: none;
    background: none;
    font-size: 1em;
    padding: 0.5em;
    color: inherit;
    flex: auto 1 1;
    width: 100%;
    background: none;
    background-color: transparent;
  }

  .dcr-input-icon-container .fa {
    padding: 10px;
  }

  .dcr-input-icon-container .fa,
  .dcr-input-icon-container .fas {
    color: #666;
  }

  .dcr-dispatch-scanner-img {
    position: relative;
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }

  .dcr-options {
    padding: 15px 0;
    width: 100%;
  }

  .dcr-options ul {
    margin: 0;
    padding: 0;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 20px;
  }

  .dcr-options ul li {
    color: black;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 16px;
    width: 33.3%;
    padding: 12px;
    text-align: center;
    font-weight: bold;
  }
  .FlagMissing{
    background-color: #000;
    width: 49%;
    border-radius: 5px;
    color: #fff;
    border: none;
    height:35px;
  }
  .flagMissingExit{
    background-color: #fff !important;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(151, 151, 151, 0.3) !important;
    width: 49%;
    font-weight: 500;
    height:35px;
    color: #333 !important;
    text-align: center;

  }
  .dcr-options .active {
    background-color: #ff4000;
    color: #fff;
  }

  .enter-ubl-button button {
    background-color: #ff4000;
  }

  .enter-ubl-button .fa-plus {
    color: #fff;
  }

  .trp-container {
    display: flex;
    padding: 20px;
    justify-content: space-evenly;
    display: none;
  }

  .trp-container div {
    width: 130px;
    height: 90px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
  }

  .trp-container div p:first-child {
    margin-bottom: 0;
    padding: 10px;
    text-align: center;
    background-color: #999;
    font-weight: bold;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
  }

  .trp-container div p:nth-child(2) {
    margin-bottom: 0;
    padding: 20px;
    font-weight: bold;
    text-align: center;
  }

  .dcru-no-items-found {
    text-align: center;
    color: #ccc;
    font-size: 18px;
    padding: 60px;
    border-top: 1px solid #ccc;
  }

  .dcru-buttons,
  .dcru-buttons-ubl {
    padding: 5px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-evenly;
  }
  .p10 {
    padding: 10px !important;
  }

  .dcru-buttons a:first-child {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    width: 49%;
    font-weight: 500;
    color: #333;
    text-align: center;
  }

  .dcru-buttons button:nth-child(2) {
    background-color: #000;
    width: 49%;
    border-radius: 5px;
    color: #fff;
    border: none;
  }

  .dcru-buttons-ubl button:first-child {
    background-color: #000;
    width: 49%;
    border-radius: 5px;
    color: #fff;
    border: none;
    padding: 10px;
  }

  .scanned-ubl-results {
    padding: 10px 15px;
    border-top: 1px solid #ccc;
  }

  .scanned-ubl-results .sur-header p {
    font-weight: 500;
    font-size: 14px;
  }

  .scanned-ubl-results .sur-header div {
    text-align: right;
  }

  .scanned-ubl-results .sur-header div input {
    margin-right: 5px;
  }
  .blackcolor {
    color: black !important;
  }
  .scanned-ubl-results .sur-header div label {
    margin-bottom: 0;
    display: inline;
  }

  .sur-list {
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    margin-bottom: 10px;

    .sur-bc-view p:nth-child(2) {
      font-size: 12px;
      //margin-left: 5px;
    }
    .sur-bc-view a {
      float: right;
      margin-top: -25px;
      margin-right: 10px;
    }
    .sur-bc-view i {
      margin-right: 5px;
    }
  }

  .sur-list div:first-child {
    padding: 10px 10px 0 10px;
  }

  .sur-list div span:first-child {
    color: #888;
    font-size: 13px;
  }

  .sur-list div span:nth-child(2) {
    font-size: 13px;
  }

  .sur-list div span:nth-child(3) {
    float: right;
    margin-right: 5px;
  }

  .sur-list div p:nth-child(4) {
     margin: 7px 0px;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sur-list div p:nth-child(5) {
    margin: 5px 0;
    color: #888;
    font-size: 12px;
  }

  .sur-list div:nth-child(2) {
    background-color: #f9f9fe;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius:10px;
    padding: 10px 5px 5px 10px;
  }

  .sur-list div p:first-child {
    margin-bottom: 0;
    margin-left: 5px;
  }

  .sur-list div p:nth-child(2) {
    margin-bottom: 0;
  }
  .pointernon
  {
    pointer-events: none;
  }
  .sur-list div p i {
    margin-right: 5px;
  }

  .dcru-vdc {
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .vd-highlighted {
    background-color: #87cefa;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
  }

  .dcr-modal-content {
    border-radius: 10px;
    .modal-header {
      background-color: #888;
      color: #fff;
      border-radius: 5px;
    }
    .modal-header span {
      color: #fff;
      font-weight: 300;
    }
    .dcr-modal-alert {
      text-align: center;
      color: #ff4000;
      font-size: 18px;
      margin-top: 20px;
      i {
        margin-right: 10px;
      }
    }
    .dcr-modal-alert-button {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      button {
        width: 200px;
        background-color: #ff4000;
        border-radius: 10px;
      }
    }
    .dcr-modal-confirmation {
      text-align: center;
      font-size: 18px;
      margin-top: 20px;
      font-weight: 500;
    }
    .dcr-modal-confirmation-button {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      :first-child {
        width: 40%;
        border: 1px solid rgba(151, 151, 151, 0.3);
        border-radius: 10px;
        margin-right: 10px;
        background-color: #fff;
        color: #000;
      }
      button:nth-child(2) {
        background-color: #ff4000;
        border-radius: 10px;
        width: 40%;
        color: #fff;
      }
    }
  }

  .invalid-ubl-num {
    margin-top: 5px;
    color: #ff4000;
  }

  /* CBL styles */
  .cbl-header-results {
    padding: 15px;
    background-color: #87cefa;
    margin-top: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    :nth-child(2) {
      padding-left: 70px;
    }
  }

  .cbl-results {
    padding: 15px;
    border: 1px solid #ccc;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
    li {
      display: inline;
      span {
        padding-left: 5px;
      }
    }
    i {
      color: #87cefa;
      cursor: pointer;
    }
  }

  .cbx-missing-items {
    :first-child {
      padding: 10px;
      .total-cbl {
        float: right;
        margin-right: 10px;
        margin-top: 5px;
      }
    }


    .cbx-message {
      padding: 10px 0 10px 0;
      background-color: #eebb99;
      p {
        margin: 0;
      }
    }
  }

  .addMissingButton {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 50%;
  }
  .recievecbl:disabled,
  .addMissingButton:disabled {
    background-color: gray !important;
  }

  .noMissingBtn {
    background-color: white !important;
    color: #000 !important;
    border: 1px solid #ccc;
    margin-right: 10px;
  }

  /* UNIVERSAL */
  .mt-5 {
    margin-top: 5px !important;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .padt-5 {
    padding-top: 5px;
  }

  .pt-12 {
    padding-top: 12px;
  }

  .pt-15 {
    padding-top: 15px;
  }

  .pr-15 {
    padding-right: 15px;
  }

  .pl-5 {
    padding-left: 5px;
  }

  .pl-15 {
    padding-left: 15px;
  }

  .pl-75 {
    padding-left: 75px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fw-500 {
    font-weight: 500;
  }

  .gray {
    color: #888;
  }

  .white {
    color: #fff !important;
  }

  .black {
    color: #000 !important;
  }

  .bg-black {
    background-color: #333 !important;
  }
  .table.cbl_list_table {
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
  }
  .table.cbl_list_table:last-child {
    border: none;
  }

  .bc-red {
    border-color: #ff2168;
  }
}
