.tabs-container {
  background-color: #ff4000;
}
.tabs__tab
{
  width: 150px;
  height: 40px;
  font-size: 14px;
  font-family: 'open_sanssemibold';
  background: none;
  border-color: none;
  border: none;
  outline: none;
  text-align: start;
 
}
.tabs__tab:hover
  {
    //color:$brand-primary-color;
    //border-bottom: 1px solid $brand-primary-color;
  }
  .tabs__tab:focus { 
    outline: none !important;
  }
.tabs__tab-active
{  
  background-image: linear-gradient(#ff4000,#ff4000);
  background-size: 30% 2px;
  background-position: bottom left;
  background-repeat: no-repeat;
  color: #ff4000;
}

hr.mt-1{
  margin-top : 1px!important;
}

