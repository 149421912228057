#shipment_component{
.grey1{
    color: #b6b6b6;
}
.black{
    color: #4c4c4c;
}
.gray{
    color: #aaaaaa;
}
.bggray{
    background-color: #f4f4f4;
}
.bgselago{
    background-color: #f9f9fe !important;
}
.bluec{
    color: #3a89fd;
}
.bg-light-black{
    background: #5e5e5e !important;
    
}
.whitetext{
    color: #fff !important;
}
.bgred{
    background-color: #ff3d1b !important;
}
}