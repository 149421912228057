// Typo font face open sans
// Typo font face Open Sans Regular
@font-face {
    font-family: 'open_sansregular';
    src: url('../../../assets/fonts/opensans/open-sans-regular/opensans-regular-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/opensans/open-sans-regular/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Typo font face Open Sans Semibold
@font-face {
    font-family: 'open_sanssemibold';
    src: url('../../../assets/fonts/opensans/open-sans-semibold/opensans-semibold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/opensans/open-sans-semibold/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Typo font face Open Sans Bold
@font-face {
    font-family: 'open_sansbold';
    src: url('../../../assets/fonts/opensans/open-sans-bold/opensans-bold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/opensans/open-sans-bold/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Typo font face Open Sans Extra Bold
@font-face {
    font-family: 'open_sansextrabold';
    src: url('../../../assets/fonts/opensans/open-sans-extra-bold/opensans-extrabold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/opensans/open-sans-extra-bold/opensans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Typo font face Open Sans Light
@font-face {
    font-family: 'open_sanslight';
    src: url('../../../assets/fonts/opensans/open-sans-light/opensans-light-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/opensans/open-sans-light/opensans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



