
@font-face {
    font-family: 'Libre Barcode 128 Text';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/librebarcode128text/v16/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5QVrS6Q.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.f14{
    font-size: 14px;
}
.fw5{
    font-weight: 500;
}
.fw6{
    font-weight: 600 !important;
}
.fw7{
    font-weight: 700 !important;
}
.fs10{
    font-size: 10px;
}
.fs12{
    font-size: 12px;
   
}
.barcode-font{
    font-family: 'Libre Barcode 128 Text';font-size: 24px;
}
.fa-arrow-left{
    color: #0d6efd !important;
}
.f18{
    font-size: 18px;
}
