.card-content {
  position: relative;
  // z-index: 0;
}

.item-num,
.view-det-link,
.reason-code,
.item-qty {
  font-size: 12px;
}

.reason-code {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  width: 70%;
  display: inherit;
  padding-bottom: 9px;
}

.item-price {
  color: $brand-primary-color;
  font-family: "open_sanssemibold";
  font-size: 13px;
}

.item-name {
  font-size: 14px;
  font-family: "open_sanssemibold";
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 5px;
}

.pb-item-num {
  padding-bottom: 5px;
}

.item-level-tag {
  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 13px;
    padding-left: 0;
    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }
  .btn-item-tag {
    background: $brand-primary-color;
    color: $white;
    text-decoration: none;
    &:hover {
      color: $white;
    }
  }
  .btn-item-tagged {
    width: 50%;
    background: $brand-primary-color;
    border-radius: 5%;
    color: $white;
    opacity: 0.5 !important;
    border: 0;
    filter: brightness(0.9);
    &:hover {
      color: $white;
    }
  }
  .btn-item-tagged-edit {
    width: 50%;
    background: $brand-primary-color;
    color: $white;
    border-radius: 5%;
    border: 0;
    &:hover {
      color: $white;
    }
  }
  .btn-item-edit {
    width: 50%;
    font-family: "open_sanssemibold";
    background: url(../../images/edit-icon.svg) no-repeat 20% center;
    border: none;
    color: $color0255e1;
    &:hover {
      color: $color0255e1;
      text-decoration: underline;
    }
    &:focus {
      outline: none !important;
    }
  }
}

.pl-2 {
  padding-left: 2.5px !important;
}

.advanced-search,
.rtn-table-search {
  input.input-search {
    background: url(../../images/search-icon-sm.svg) no-repeat 4% center;
  }
}

.item-level-tag {
  input.form-control {
    background: url(../../images/barcode-thin.svg) no-repeat 98% center;
    padding-right: 30px;
  }
}

.rtn-table-search {
  input.form-control {
    padding-left: 36px;
    font-size: 13px;
  }
}

.card-slide {
  background: #fff;
  border: 1px solid rgba(151, 151, 151, 0.3);
  z-index: 2;
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  height: 400px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  left: 0px;
  top: 20%;
}

.barcode-validation-failed {
  padding: 2px;
  font-family: "open_sanssemibold";
  padding-left: 4px;
  font-size: 10px;
  color: #ff4000;
  padding-top: 5px;
}

.barcode-validation-failed {
  padding: 2px;
  font-family: "open_sanssemibold";
  padding-left: 4px;
  font-size: 8px;
  color: #ff4000;
}

.card-slide {
  .card {
    background: rgba(245, 245, 245, 0.5);
    margin: 15px 0px 10px;
    border: none;

    .card-details {
      margin-top: 5px;
      padding: 0 0 0 10px;
      h5 {
        font-size: 12px;
        font-family: "open_sanssemibold";
        display: block;
      }
      h6 {
        font-size: 10px;
        font-family: "open_sansregular";
        display: block;
      }
    }
  }
}

.sort {
  width: auto;
  float: right;
  position: absolute;
  z-index: 2;
  right: 0;
  border-radius: 7px;
}

.sort-by {
  font-weight: 500 !important;
  font-size: 14px;
  color: #adb5bd;
}

.mr-8 {
  margin-right: -8px;
}

.view-details {
  border-radius: 15px;
  width: 30%;
}
