#dcdespatch_component {
  .hr_line {
    margin-right: -10px;
    margin-left: -10px;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
  }

  .form-inline {
    padding-bottom: 10px;
  }

  .back-button{
    border-color: #ffffff;
    border-radius: 5px;
    height: 35px !important;
    width: 140px;
    background-color: white;
    color: #000;
    margin: 0px 5px 0px 0px;
    font-size: 12px;
    outline-color: white;
    box-shadow: none;
  }

  .m-0{
    margin: 0px;
  }

  .mr-5{
    margin-right: 5px!important;
  }

  .modal-transparent{
    background: rgba(52, 52, 52, 0.8);
  }

  .scanned-ubl-modal-content{
    font-size: 14px!important;
    margin-top: 0px!important;
    padding-bottom: 0px!important;
  }

  .height-100{
    height: 100vh;
  }

  .p-0{
    padding: 0px!important;
  }

  .scanned-ubl{
    display: flex;
    justify-content: center;
    color: red;
    margin-bottom: -10px;
  }
  .input-search-dcitem {
    background: url(../../images/barcode-thin.svg) no-repeat 98% center;
    padding-right: 30px;
    padding-left: 10px;
    border-radius: 5px 0px 0px 5px;
  }

  .dc-location {
    border-radius: 5px !important;
    background-color: white;
  }

  .faSearch {
    position: absolute;
    top: 15px;
    left: 17px;
    color: gray;
  }

  .faplus {
    background: #ff4000;

    color: white;
  }

  .faMapMarker {
    position: absolute;
    top: 13px;
    left: 11px;
    color: gray;
  }

  .bg-color {
    background: #f8f8f8;
  }

  .rtn-search-results {
    min-height: 70vh;
    padding: 10px 0px;
    border-radius: 0px;
  }

  .fapMark-margin {
    margin: 5px 5px 0px 0px;
  }

  .scanned-ubl-results{
    padding: 10px 15px;
    border-top: 1px solid #ccc;
    margin-bottom: 45px;
  }
  .dcitem-scanner-img {
    position: relative;
    height: 25px;
    width: 25px;
    right: 8.7em;
    top: 7.5px;
    z-index: 888;
  }

  .putaway-input-section {
    box-shadow: none;
    border-radius: 0px;
    padding: 10px !important;
  }

  .dcitem-scanner {
    position: relative;
    height: 25px;
    width: 25px;
    right: 5.7em;
    top: 7.5px;
    z-index: 888;
  }

  .add-carrier-btn {
    border-color: #000000;
    border-radius: 5px;
    height: 35px !important;
    width: 140px;
    background-color: black;
    color: #fff;
  }

  .barcode-validation-error {
    font-family: "open_sanssemibold";
    font-size: 10px;
    color: #ff4000;
    margin-left: 155px;
  }

  .uil-list {
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    background-color: #888888;
    margin: 5px;
  }

  .edit-summary-details {
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    background: #f8f8f8;
    cursor: pointer;
  }

  .box-icon {
    color: #cccc;
    height: auto;
    width: auto;
    border-radius: 50%;
    box-shadow: 0 0 5px #cccc;
    padding: 10px;
  }

  .search-despatch {
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-top: none;
    border-radius: 10px;
    padding: 10px;
    background: #f8f8f8;
  }

  search-despatch-modify {
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-top: none;
    border-radius: 10px;
    padding: 10px;
  }

  .search-results {
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    background-color: #add8e6;
  }

  .search-results-contents {
    background-color: white;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    border-top: none;
    padding: 10px;
  }

  .bottomContents {
    border-top: 2px solid #cccccc;
    padding: 10px;
  }

  .card-padding {
    padding: 10px;
  }

  .uil-list .myclass {
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0px;
  }

  .ubl-cards-col {
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 8px;
  }

  .ubl-cards-left-col {
    background-color: #808080;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ubl-cards-right-col {
    border-bottom: 1px solid #cccccc;
  }

  .ubl-cards-top-border {
    border-top: 1px solid #cccccc;
  }

  .ubl-card-index {
    color: white;
  }

  .ubl-card-image {
    padding: 0 5px;
  }

  .ubl-edit-text {
    color: #006ee6;
  }

  @media only screen and (max-width: 600px) {
    .barcode-validation-error {
      margin-left: 0px;
    }

    .section-four {
      position: fixed;
      bottom: 0px;
    }

    .barcode-font-size {
      font-size: 17px;
    }
  }

  .dc_location {
    font-size: 13px;
  }

  .search-panel label {
    font-size: 13px;
  }

  .search-panel select {
    font-size: 13px;
    font-family: "open_sansregular";
  }

  .section-four {
    background-color: #fff;
    box-shadow: 0px 0px 10px #888888;
    padding: 10px;
    display: inline-block;
    margin-top: 10px;
    // margin-bottom: 10px;
    // border-radius: 0 0 10px 10px;
  }

  .ro-supname{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 12px!important;
  }



  .brand{
    font-size: 12px;
    font-family: "open_sanssemibold";
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 5px;
  }

  .item-name{
    font-size: 14px;
    font-family: "open_sanssemibold";
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 5px;
  }
  .ctbtn {
    border-radius: 10px !important;
    height: fit-content;
    text-decoration: none;
    background-color: #ff4000;
    color: white;
    width: 45%;
    font-size: 12px;
    font-weight: 600;
  }

  .ctbtn-white-bg {
    border-radius: 10px !important;
    height: fit-content;
    text-decoration: none;
    width: 45%;
    background-color: #ffffff;
    color: #000000;
    border-color: #dee2e6;
    font-size: 12px;
    font-weight: 600;
  }

  .fw-600{
    font-weight: 600;
  }

  .c-red{
    color: red;
  }

  .mt-7{
    margin-top: -7px;
  }

  .complete-putaway-btn {
    border-color: #000000;
    border-radius: 5px;
    height: 35px !important;
    width: 140px;
    background-color: black;
    color: #fff;
  }

  .cancel-btn {
    margin-right: 3em;
    border: none !important;
    background: none;
    color: #2f2f2f;
    font-weight: 500;
    text-decoration: none;
  }

  .btn-primary {
    background-color: #ff4000;
  }

  .search-button {
    background-color: #000;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    width: 49%;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .modal-title {
    font-weight: 300;
    font-family: "open_sansbold";
    color: white;
  }

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 500px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .modal.left .modal-content,
  .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .modal.left .modal-body,
  .modal.right .modal-body {
    padding: 15px 15px 80px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .modal.right.fade.in .modal-dialog {
    right: 0;
  }

  /* ----- MODAL STYLE ----- */
  .modal-content {
    border-radius: 10px;
    border: none;
  }

  .modal-header {
    border-bottom-color: #eeeeee;
    background-color: #ff4000;
  }

  .multiSelectContainer {
    background: url(../../images/search-icon-sm.svg) no-repeat 3% center;
  }

  .searchWrapper {
    padding-left: 36px;
  }

  .searchBox {
    margin-bottom: 5px;
  }

  /* CARD TILES INNER TEXT STYLING */

  .tile-item {
    margin-bottom: 10px;
    display: inline-flex;
  }

  .barcode-font-size {
    font-size: 27px;
  }

  .brand-desc {
    font-size: 12px;
  }

  .dc-despatch-item-label {
    font-size: 14px;
    font-family: "open_sanssemibold";
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .draft-modal-confirmation {
    text-align: center;
    font-size: 12px;
    margin-top: 20px;
    font-weight: 600;
    color: black;
  }

  .tickmark-dc-despatch {
    height: 30px;
    text-align: center;
    margin-top: 10px;
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  .number-of-ubl{
    font-family: 'open_sansregular';
    font-size: 12px;
    color: #495057;
  }

  .ubl-count {
    font-family: 'open_sansregular';
    font-size: 12px;
    color: #495057;
    font-weight: 600;
    margin-left: 10px;
  }

  .supplier-details {
    font-size: 12px;
    font-family: 'open_sansregular';
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
  }

  .input-disable{
    background-color: #e9ecef!important;
  }

  .weight{
    width: 50%!important;
    float: left;
  }

  .ml-10{
    margin-left: 10px;
  }

  .carton-box-number-border{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .footer-position{
    right: 0;
    bottom: 0;
    position: fixed;
  }
}