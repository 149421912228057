.card-content-ubl {
  position: relative;
  z-index: 0;
  border: 1px solid rgba(151, 151, 151, 0.3);
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  height: 130px;
  
  border-radius: 8px;
  hr {
    margin: 0.5rem 0;
  }
}

.item-id,
.check-link,
.reason-code,
.item-qty {
  font-size: 12px;
}

.item-price {
  color: $brand-primary-color;
  font-family: "open_sanssemibold";
  font-size: 13px;
}

.item-ublname {
  font-size: 14px;
  font-family: "open_sanssemibold";
  text-overflow: ellipsis;
  white-space:nowrap;
  padding-top: 10px;
  overflow:hidden;
  
}

.item-level-tag {
  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 13px;
    padding-left: 0;
    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }
  .btn-item-tag {
    background: $brand-primary-color;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  .btn-item-add {
    background: url(../../images/plus-icon.svg) no-repeat $brand-primary-color
      left;
    color: $white;
    width: 100px;

    &:hover {
      color: $white;
    }
  }
}
.close-modal {
  background: url(../../images/close-icon.svg) no-repeat 98% center;
  width: 100px;
  height: 20px;
}
.notification::after {
  border-bottom: 15px solid white;
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  top: -10px;
  left: 80%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.advanced-search,
.rtn-table-search {
  input.input-search {
    background: url(../../images/search-icon-sm.svg) no-repeat 4% center;
  }
}

.item-level-tag {
  input.form-control {
    background: url(../../images/barcode-thin.svg) no-repeat 98% center;
  }
}
.cart-barcode {
  background: url(../../images/barcode-img.svg) no-repeat;
  height: 22px;
}
.cart-delete {
  background: url(../../images/delete-icon.svg) no-repeat;
  height: 22px;
  width: 20px;
  cursor: pointer;
}
.cart-view {
  background: url(../../images/view-icon.svg) no-repeat 98% center;
  height: 22px;
  width: 20px;
  margin-right: 8px;
}
.cart-add-ubl {
  background: url(../../images/plus-icon.svg) no-repeat;
}

.rtn-table-search {
  input.form-control {
    padding-left: 36px;
    font-size: 13px;
  }
}

.card-ublslide {
  background: #fff;
  border: 1px solid rgba(151, 151, 151, 0.3);
  z-index: 200;
  box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
  height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 0px;
  top: 0px !important;
}

.card-ublslide {
  .card {
    background: rgba(245, 245, 245, 0.5);
    margin: 15px 0px 10px;
    border: none;

    .card-details {
      margin-top: 5px;
      padding: 0 0 0 10px;
      h5 {
        font-size: 12px;
        font-family: "open_sanssemibold";
        display: block;
      }
      h6 {
        font-size: 10px;
        font-family: "open_sansregular";
        display: block;
      }
    }
  }
}
