.layout-desktop {
  position: relative;
  main {
    background: #f8f8f8;
    //height: 100vh;
  }

  .header-logo {
    position: absolute;
    z-index: 99;
    margin: 20% 19%;
  }

  &.loginPageNew {
    .form-section {
      width: 70%;
      margin: 0 auto;
    }
  }

  #footer {
    position: absolute;
    bottom: 20px;
  }
}

.layout-desktop {

  .login-inner-form {
    .form-group {
      margin-bottom: 40px;
    }
  }

  .bo-login-btn {
    margin: 40px 0 0;
  }
}



// sidenav

/*
 * Sidebar
 */

.layout-desktop {
  .sidebar {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    z-index: 999; /* Behind the navbar */
    padding: 0 0 20px 0;
    // padding: 48px 0 0; /* Height of navbar */
    background: $white;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    -webkit-box-shadow: 2px 0 10px -4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 0 10px -4px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 0 10px -4px rgba(0, 0, 0, 0.3);
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }
}

.sidebar-bo-logo {
  padding: 25px 20px;
  font-size: 20px;
  display: block;
  text-align: left;
  color: $black;
  font-family: 'open_sanslight';
  line-height: 1.2;

  span {
    h4 {
      text-align: center;
      font-size: 21px;
      font-family: 'open_sanssemibold';
      small {
        display: block;
        font-size: 10px;
        color: #555555;
      }
    }
    display: block;
    font-family: 'open_sanssemibold';
    font-size: 21px;
    text-align: center;
  }

  &:hover {
    text-decoration: none;
    color: $black;
  }
}


    .dropdown-toggle{
        &::after {
          position: absolute;
          right: 7%;
          color: white;
          top:25px;
        }
    }


.bg-brand-dark {
  background: $brand-primary-color;
  height: 60px;
  z-index: 9;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  /* overflow-x: hidden;
     overflow-y: auto; 
     Scrollable contents if viewport is shorter than content. */
}

.sidebar-sticky {
  .logo {
    text-align: center;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: block;
  }
}

.landingpage-menu {
  &.px-4 {
    padding: 0 10px !important;
}
  margin: 10px 0;

  .media {
    background: $white;
    padding: 40px 30px;
    border: 1px solid #ddd;
    border-radius: 17px;
    height: 120px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .menutiles-icon {
      font-size: 30px;
      display: block;
      color: $brand-primary-color;
      margin: 5px 25px 0 0;
    }

    &:hover {
      background: $black;
      color: $white;
      transition: all 0.5s ease;
    }
  }

  .mediamismatch {
    background: $white;
    // padding: 40px 30px;
    border: 1px solid #ddd;
    border-radius: 17px;
    height: 202px;
    width: 330px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .menutiles-icon {
      font-size: 30px;
      display: block;
      color: $brand-primary-color;
      margin: 5px 25px 0 0;
    }

    .mediamismatchtitle{
      padding-top: 17px;
    padding-left: 10px;
    font-size: medium;
    font-family: 'open_sanssemibold';
    text-align: left;
    letter-spacing: 0px;
    color: #3D3D3D;
    opacity: 1;
      .mediamismatchnumber{
        text-align: right;
        padding-left: 50px;
      }

    }

    .mediamismatchicon{
      text-align: center;
      padding: 10px;
    }

   
.mismatchwrap {
  height: 45px;
    width: 330px;
    /* border: 1px solid #aaa; */
    border: 1px solid #DBDBDB;
    background-color: #FF401A;
    /* margin: 10px; */
    margin-top: 40px;
    /* display: flex; */
    border-radius: 0px 0px 17px 17px;
    /* border-bottom-right-radius: 17px; */
    /* border-bottom-left-radius: 17px; */
    text-align: center;
}

.mismatchwrapview{
    background-image: url(/images/Icons/mismatchright.svg), url(/images/Icons/mismatchright.svg);
    background-repeat: no-repeat,no-repeat;
    background-position: 190px 17px, 194px 17px;
    padding: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'open_sansregular';
}

.mismatchwrapview:focus {
  outline: none;
  }


    // &:hover {
    //   background: $black;
    //   color: $white;
    //   transition: all 0.5s ease;
    // }
  }

}

.mediamismatchbutton{
  border-style: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
 
}
.mediamismatchbutton img{
  vertical-align: top;
}
.mediamismatchbutton:focus{
  outline: none;
}
.mismatchfixturecard{
  margin-bottom: 10px;
}
.mismatchfixturecardrow{
   margin-bottom: 10px;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 20px 15px;

  &:hover {
    background: $black;
    color: $white;
    transition: all 0.5s ease;

    .icon-stocktake,
    .icon-report {
      animation: full-circle 1.1s ease-out;
    }
  }
}

.sidemenu {
  .nav-item {
    .nav-link {
      .icon-left {
        float: left;
        margin-right: 15px;
      }

      .menu-icon-right {
        float: right;
      }
    }
  }
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

@keyframes full-circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.sidebar .nav-link.active {
  color: #fff;
  background: $black;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.bo-breadcrumb {
  margin-top: 40px;
  background: $white;
  padding: 10px;
  -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.3);

  h5 {
    margin-bottom: 0;
    color: $text-color;
    display: inline-block;
    font-size: 18px;
    font-family: 'open_sanssemibold';
  }

  .breadcrumb {
    float: right;
    background: none;
    padding: 4px;
    margin-bottom: 0;
  }
}

.reset-padding {
  padding-left: 0;
  padding-right: 0;
}

.ldgpage-nav {
  .ldgpage-icons {
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px 25px 0 0;
  }

  .media-body {
    h5 {
      font-size: 16px;
      line-height: 1.3;

      span {
        display: block;
      }
    }
  }
}

.landing-page-menu-icon-height{
  height: 50px;
}

.menu-tiles {
  a {
    color: $text-color;

    &:hover {
      text-decoration: none;
      background: $black;
    }
  }
}

.landing-footer {
  position: fixed;
  bottom: 0;
  background: $black;
  width: 100%;

  span {
    text-align: right;
    display: block;
    font-size: 11px;
  }
}

.menu-toggle-icon {
  .navbar-toggler {
    border-color: inherit !important;
    border: inherit !important;
    padding: 0;
    color: inherit !important;

    .navbar-togglericon img {
      width: 27px;
    }

    &:focus {
      outline: none !important;
    }
  }
}

.position-loader {
  position: fixed!important;
  left: 47%;
  top: 50%;
}

.position-login{
  position: fixed!important;
  left: 45%;
  top: 45%;
}

.top-right-border{
  border-top-right-radius: 0!important;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: 100!important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mrgin-tp{
  margin-top: 20px;
}

.mrgin-btm{
  margin-bottom: 20px;
}

.reasonBlack{
  color: $black;
}

.reasonRed{
  color: #FF401A;
}

.mrgin-tp{
  margin-top: 10px;
}

.margin-navbar{
  margin-top: 10px;
  margin-left: -23px;
}

.input-border{
    height: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-color: white;
    border-right-color: white!important;
    &:focus{
      border-right-color: white!important;
    }
}

.clear-height{
  height: 40px;
}

.clear-border{
  border-left-color: white;
  border-right-color: white;
  border-top-color: #CED4D9;
  border-bottom-color: #CED4D9;
  &:hover {
    border-left-color: white;
    border-top-color: #CED4D9 !important;
  border-bottom-color: #CED4D9!important;
  border-right-color: white;
  }

  &:focus{
    border-left-color: white;
    border-top-color: #CED4D9 !important;
  border-bottom-color: #CED4D9!important;
  border-right-color: white;
  background-color: white!important;
  outline-color: transparent!important;
  }
}

.barcode-scanner-border{
  border-left-color: transparent;
  border-top-color: #CED4D9!important;
  border-bottom-color: #CED4D9!important;
  border-right-color: #CED4D9!important;
  height: 40px;
  &:hover {
  border-left-color: white;
  border-top-color: #CED4D9 !important;
  border-bottom-color: #CED4D9!important;
  border-right-color: #CED4D9!important;
  }
}

.barcode-scanner-width{
  width: 30px;
}

.input-group-mt{
  margin-top: 12px;
}

.input-margin{
  margin-top: 8px;
  max-width: fit-content;
}

.width-input{
  width: 45%;
}

.topCenter {
  position: absolute;
  top: 8px;

  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 10;

  #toast{
    border-radius: 5px;
    min-width: max-content;
    background-color: white;
    margin: 0px auto;
  
  .vertical-toast-border{
    background: #59ad29;
    width: 5px;
    display: block;
    position: absolute;
    height: 90%;
    left: 5px;   
    top: 3px;
    bottom: 0;
    border-radius: 20px;
    }

  .toast__content {
      padding-left: 10px;
      padding-right: 10px;
      height: 60px;
      }

  .tickmark {
      margin-left: 1%;
      float: left;
      height: 30px;
      margin-right: 5%;
      text-align: center;
    }
   img.tickmark {
      margin-top: 15px;
    }


  .toast__content {
      min-width: 100%;
    }

  .toast__message {
      margin-top: 0;
      font-size: 12px;
      font-family: "open_sanssemibold";
      margin-bottom: 0;
      color: black;
    }
    
  .toast-close{
        top: 0;
        position: absolute;
        right: 0;
        padding-right: 5px!important;
        
    }

  .toast__type {
      color: #3e3e3e;
      font-weight: 700;
      font-size: 16px;
      font-family: "open_sanssemibold";
      color: rgba(89, 173, 41, 255);
      margin-top: 0;
      margin-bottom: 8px;
    }
}

}


