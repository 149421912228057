/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 500px;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}

        
/*Right*/
	.modal.right.fade .modal-dialog {
		right: -500px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.show .modal-dialog {
		right: 0;
	}

/* ----- MODAL STYLE ----- */
	.modal-content {
		border-radius: 0;
		border: none;
	}

	

/* View Summary Details */

.summary-modal {
	.modal-content {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
	.modal-header {
		padding: 1.5rem 1rem;
		background-color: $brand-primary-color;
		h4 {
			font-size: 18px;
			color: $white;
		}
		
		.close {
			color: $white;
			opacity: 1;
			font-weight: 200;
			font-size: 35px;
			margin-top: -22px;
		}
		.close:focus,.close:hover
		{
			opacity: 1;
			outline: none;
		}
	}

	.card-header {
		background: #D1F4FF;
		border-bottom: none;
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
		h6{
			font-size: 13px;
			font-family: 'open_sanssemibold';
			margin-bottom: 0;
		}
		
	}
	
	.card {
		box-shadow: 0px 10px 40px rgba(196, 196, 196, 0.25);
		border: none;
		border-radius: 8px;
	}

	.card-body {
		
		h6{
			font-size: 13px;
			font-family: 'open_sanssemibold';
			margin-bottom: 0;
		}
		span {
			font-size: 12px;
			
		}
	}

	.item-name{
		white-space: inherit;
    	line-height: 1.5;
    	font-size: 12px;
	}
}

